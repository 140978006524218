<template>
  <event-product-browse-layout
    v-model:selected-tab="filters.type"
    :tabs="tabs"
  >
    <template #section-title-name>
      The Tastes of {{ selectedEvent?.name }}
    </template>

    <!--        <template #sub-stick-header>-->
    <!--          <div-->
    <!--            class="flex flex-col-reverse justify-between gap-y-4 md:flex-row md:gap-0">-->
    <!--            <div class="flex w-full flex-col gap-4">-->
    <!--              <div class="flex flex-row gap-2 overflow-auto">-->
    <!--                <button-->
    <!--                  v-for="menuType in availableMenuTypes"-->
    <!--                  type="button"-->
    <!--                  class="whitespace-nowrap rounded-full p-3 text-sm font-bold md:px-4 md:py-2 md:text-lg"-->
    <!--                  :class="{-->
    <!--                    'bg-dark-menu text-white': menuType.value === pageQuery.type,-->
    <!--                    'bg-white text-paragraph': menuType.value !== pageQuery.type,-->
    <!--                  }"-->
    <!--                  @click="handleChangeType(menuType.value)">-->
    <!--                  {{ menuType.name }}-->
    <!--                </button>-->
    <!--              </div>-->
    <!--              &lt;!&ndash;          <div :class="getSubMenuRowClassList" v-if="!hasToShowRestaurant">&ndash;&gt;-->
    <!--              &lt;!&ndash;            <query-filters&ndash;&gt;-->
    <!--              &lt;!&ndash;              main="type"&ndash;&gt;-->
    <!--              &lt;!&ndash;              :style="'simple'"&ndash;&gt;-->
    <!--              &lt;!&ndash;              :categories="filterCategories"&ndash;&gt;-->
    <!--              &lt;!&ndash;              v-model:selected="filters"&ndash;&gt;-->
    <!--              &lt;!&ndash;              @update:selected="applyFilters" />&ndash;&gt;-->
    <!--              &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--            </div>-->
    <!--            <div class="flex gap-8">-->
    <!--              <div class="flex gap-6">-->
    <!--                <span class="hidden sm:flex">Search</span>-->
    <!--                <svg-->
    <!--                  xmlns="http://www.w3.org/2000/svg"-->
    <!--                  width="24"-->
    <!--                  height="25"-->
    <!--                  viewBox="0 0 24 25"-->
    <!--                  fill="none">-->
    <!--                  <path-->
    <!--                    fill-rule="evenodd"-->
    <!--                    clip-rule="evenodd"-->
    <!--                    d="M11.1 5.3C7.62061 5.3 4.8 8.12061 4.8 11.6C4.8 15.0794 7.62061 17.9 11.1 17.9C14.5794 17.9 17.4 15.0794 17.4 11.6C17.4 8.12061 14.5794 5.3 11.1 5.3ZM3 11.6C3 7.12649 6.62649 3.5 11.1 3.5C15.5735 3.5 19.2 7.12649 19.2 11.6C19.2 16.0735 15.5735 19.7 11.1 19.7C6.62649 19.7 3 16.0735 3 11.6Z"-->
    <!--                    fill="#47474F" />-->
    <!--                  <path-->
    <!--                    fill-rule="evenodd"-->
    <!--                    clip-rule="evenodd"-->
    <!--                    d="M15.5486 16.0486C15.9001 15.6971 16.4699 15.6971 16.8214 16.0486L20.7364 19.9636C21.0879 20.3151 21.0879 20.8849 20.7364 21.2364C20.3849 21.5879 19.8151 21.5879 19.4636 21.2364L15.5486 17.3214C15.1971 16.9699 15.1971 16.4001 15.5486 16.0486Z"-->
    <!--                    fill="#47474F" />-->
    <!--                </svg>-->
    <!--              </div>-->
    <!--              <div class="flex gap-6">-->
    <!--                <span class="hidden sm:flex">Filters</span>-->
    <!--                <svg-->
    <!--                  xmlns="http://www.w3.org/2000/svg"-->
    <!--                  width="24"-->
    <!--                  height="25"-->
    <!--                  viewBox="0 0 24 25"-->
    <!--                  fill="none">-->
    <!--                  <path-->
    <!--                    d="M12.5 6.625V4.5M12.5 6.625C11.3264 6.625 10.375 7.57639 10.375 8.75C10.375 9.92361 11.3264 10.875 12.5 10.875M12.5 6.625C13.6736 6.625 14.625 7.57639 14.625 8.75C14.625 9.92361 13.6736 10.875 12.5 10.875M6.125 19.375C7.29861 19.375 8.25 18.4236 8.25 17.25C8.25 16.0764 7.29861 15.125 6.125 15.125M6.125 19.375C4.95139 19.375 4 18.4236 4 17.25C4 16.0764 4.95139 15.125 6.125 15.125M6.125 19.375V21.5M6.125 15.125V4.5M12.5 10.875V21.5M18.875 19.375C20.0486 19.375 21 18.4236 21 17.25C21 16.0764 20.0486 15.125 18.875 15.125M18.875 19.375C17.7014 19.375 16.75 18.4236 16.75 17.25C16.75 16.0764 17.7014 15.125 18.875 15.125M18.875 19.375V21.5M18.875 15.125V4.5"-->
    <!--                    stroke="#47474F"-->
    <!--                    stroke-width="1.5"-->
    <!--                    stroke-linecap="round"-->
    <!--                    stroke-linejoin="round" />-->
    <!--                </svg>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </template>-->
    <resource-paginator
      :items="items"
      :list-class="
        filters.type === 'restaurant'
          ? 'flex flex-col align-items-center gap-5 justify-content-center w-full mx-auto max-w-screen-2xl px-2 md:px-4 lg:px-6 xl:px-8'
          : 'grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-12 w-full mx-auto max-w-screen-xl px-2 md:px-4 lg:px-6 xl:px-8'
      "
      :loading="loading || fetching"
      :reached-end="!hasNextPage"
      @load-next-page="hasNextPage && fetchNextPage()"
    >
      <template #item="{ item }">
        <restaurant-card-with-products
          v-if="filters.type === 'restaurant'"
          :event-slug="selectedEvent?.slug"
          :restaurant="item"
        />
        <item-card
          v-else
          :event-slug="selectedEvent?.slug"
          :item="item"
          class="shrink-0 md:max-w-[290px]"
          image-class="h-[220px]"
        />
      </template>
    </resource-paginator>
  </event-product-browse-layout>
</template>

<script lang="ts" setup>
import EventProductBrowseLayout from '@/components/layout/EventProductBrowseLayout.vue'
import { useInfiniteTable } from '@/utils/composables/table'
import axios from 'axios'
import ResourcePaginator from '@/components/layout/ResourcePaginator.vue'
import RestaurantCardWithProducts from '@/components/ui/RestaurantCardWithProducts.vue'
import useConventionList from '@/utils/composables/conventionList'
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'

const { selected: selectedEvent } = useConventionList()

const {
  loading,
  fetching,
  items,
  sort,
  filters,
  columns,
  hasNextPage,
  fetchNextPage,
  invalidate,
} = useInfiniteTable({
  queryKey: 'browseEat',
  baseUrl: `/api/conventions/${selectedEvent.value?.slug}/products`,
  queryFn: (params) => {
    switch (params.type) {
      case 'meal':
        return axios.get(
          `/api/conventions/${selectedEvent.value?.slug}/products`,
          {
            params: {
              ...params,
            },
          }
        )
      case 'restaurant':
      default:
        return axios.get(
          `/api/conventions/${selectedEvent.value?.slug}/vendors`,
          {
            params: {
              ...params,
            },
          }
        )
    }
  },
  filters: {
    type: 'meal',
  },
})

const tabs = [
  {
    name: 'Dishes and Drinks',
    value: 'meal',
  },
  {
    name: 'Restaurants',
    value: 'restaurant',
  },
]
</script>

<style scoped></style>
