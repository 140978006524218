<template>
  <div
    ref="calendar"
    class="flex h-full grow flex-col"
  >
    <div
      id="schedule-calendar-body"
      ref="body"
      :class="[loading ? 'overflow-hidden' : 'overflow-scroll']"
      :style="[`height: ${bodyHeight + 'px'};`]"
      class="scrollbar-hide relative grow pb-20"
      @click="runTicker"
      @scroll="syncScrollThrottledDebounce"
    >
      <div
        :style="[
          `gap: ${gapY}px ${gapX}px;`,
          `grid-template-columns: repeat(${gridColumns}, ${TRACK_WIDTH}px);`,
          `grid-template-rows: ${timeLineHeight}px repeat(${
            selectedDayPreparedGroups.map((g) => g.groups).flat(1).length
          }, 150px);`,
        ]"
        class="grid-rows-fr grid-rows-auto grid"
      >
        <!-- Times array -->
        <div
          v-for="(time, index) in times"
          :key="index + '-schedule-calendar-time'"
          :class="[
            loading
              ? 'skeleton text-transparent'
              : 'bg-gradient-to-b from-[#E7ECFF] to-[#CDD4EC]',
            !loading && (index + 1) * 15 <= lineOffset / 60 / PIXELS_PER_SECOND
              ? 'opacity-50'
              : '',
          ]"
          :style="`grid-area: 1 / ${index + 1} / 2 / ${index + 2};`"
          class="sticky top-2.5 z-fixed mt-2.5 select-none rounded p-2.5 font-bold"
          data-time="time"
        >
          {{ time.toFormat('h:mma').toLowerCase().replace('m', '') }}
        </div>

        <!-- Times background -->
        <div
          class="sticky top-0 z-overlay bg-gradient-to-b from-white to-transparent"
          style="grid-area: 1/1/2/-1"
        />

        <!-- Current Time Line -->
        <div
          v-if="!loading && lineOffset > 0"
          :style="`grid-area: 1/1/${
            selectedDayPreparedGroups.at(-1).end_row + 2
          }/-1`"
          class=""
        >
          <div
            :style="{ width: lineOffset + 'px' }"
            class="pointer-events-none sticky top-0 z-tooltip h-full bg-gray-100 bg-opacity-50"
          >
            <div
              class="absolute right-0 h-full w-[2px] bg-blue-primary bg-opacity-50"
            />
          </div>
        </div>

        <!-- Groups -->
        <template
          v-for="(group, groupIndex) in selectedDayPreparedGroups"
          :key="groupIndex + '-schedule-calendar-group'"
        >
          <!-- Group Start Section -->
          <div
            v-if="!loading"
            :style="
              getGridArea(
                group.start_row + 2,
                group.end_row + 2,
                1,
                getStartSection(group)
              )
            "
            class="bg-lines z-content flex flex-col items-start justify-start rounded-lg px-5 pb-6 text-sm text-slate-500"
          >
            <div class="sticky left-0 h-full px-1 text-gray-500">
              <div class="sticky top-12 pt-4">
                <h1 class="text-lg font-bold">
                  {{ getGroupName(group) }}
                </h1>
                <small class="text-xs">has not started yet</small>
              </div>
            </div>
          </div>

          <!-- Group End Section -->
          <div
            v-if="!loading"
            :style="
              getGridArea(
                group.start_row + 2,
                group.end_row + 2,
                getEndSection(group) + 1,
                -1
              )
            "
            class="bg-lines z-content flex flex-col items-start justify-start rounded-lg px-5 py-4 text-sm text-slate-500"
          >
            <div class="sticky left-0 h-full px-1 text-gray-500">
              <div class="sticky top-12">
                <h1 class="text-lg font-bold">
                  {{ getGroupName(group) }}
                </h1>
                <small class="text-xs">has ended</small>
              </div>
            </div>
          </div>

          <!-- Group Sticky Section on left -->
          <div
            v-if="!loading"
            :style="getGridArea(group.start_row + 2, group.end_row + 2, 1, -1)"
            class="flex select-none"
          >
            <div
              :class="[
                scrollX < getStartSection(group) * (TRACK_WIDTH + gapX) ||
                scrollX > getEndSection(group) * (TRACK_WIDTH + gapX)
                  ? '-translate-x-full'
                  : '',
              ]"
              class="sticky left-0 z-floating+1 flex w-7 flex-col rounded-r-lg bg-gray-100 p-1 text-sm font-bold uppercase text-gray-500 shadow outline outline-1 outline-gray-200 duration-300"
            >
              <div class="vertical sticky top-12 whitespace-nowrap pb-5">
                {{ group.short ?? group.name }}
              </div>
            </div>
          </div>

          <!-- Subgroups  -->
          <template
            v-for="(subgroup, subgroupIndex) in group.groups"
            :key="subgroupIndex + '-schedule-calendar-subgroup'"
            v-memo="[
              loading,
              group,
              expandedActivity,
              gridPosition.colStart,
              gridPosition.colEnd,
              gridPosition.rowStart,
              gridPosition.rowEnd,
            ]"
          >
            <!-- Activity -->
            <template
              v-for="(activity, activityIndex) in subgroup"
              :key="activityIndex + '-schedule-calendar-activity'"
            >
              <!-- Loading Skeleton -->
              <div
                v-if="loading"
                :style="
                  getGridArea(
                    subgroupIndex + 2,
                    subgroupIndex + 3,
                    activity.starts_at,
                    activity.ends_at
                  )
                "
                class="skeleton min-h-[150px] rounded-lg"
                data-role="activity"
              />

              <!-- Hides elements if out of scroll -->
              <template
                v-else-if="
                  !(
                    activity.ends_at_index < gridPosition.colStart ||
                    activity.starts_at_index > gridPosition.colEnd
                  ) &&
                  !(
                    group.start_row + subgroupIndex < gridPosition.rowStart ||
                    group.start_row + subgroupIndex > gridPosition.rowEnd
                  )
                "
                :key="activityIndex + '-schedule-calendar-activity-item'"
                v-memo="[
                  expandedActivity,
                  gridPosition.colStart,
                  gridPosition.colEnd,
                  gridPosition.rowStart,
                  gridPosition.rowEnd,
                ]"
              >
                <!-- Activity filler -->
                <div
                  v-if="activity.type === 'filler'"
                  :style="
                    getGridArea(
                      group.start_row + 2 + subgroupIndex,
                      group.start_row + 3 + subgroupIndex,
                      activity.starts_at_index + 1,
                      activity.ends_at_index + 1
                    )
                  "
                  class="flex min-h-[150px] flex-col items-start justify-center gap-1.5 rounded-lg bg-[#EDF0F8] px-4 py-3"
                >
                  <div class="sticky left-10">
                    <p class="text-xs text-gray-700">
                      <b>{{ activity.next_name }}</b>
                      starts in {{ activity.time }} hours.
                    </p>
                    <button
                      class="flex items-center gap-1 text-xs text-blue-primary"
                      @click="scrollToX(activity.next_position_x)"
                    >
                      Jump to next activity
                      <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 16 16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.375 3.49951L12.875 7.99951L8.375 12.4995M12.25 7.99951H3.125"
                          stroke="#1613BD"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <!-- Activity -->
                <schedule-activity-presenter
                  v-else
                  :id="'activity-' + activity.id"
                  v-memo="[
                    expandedActivity,
                    activity,
                    activity.starts_at_index,
                    activity.ends_at_index,
                  ]"
                  :activity="activity"
                  :class="[
                    activity.ends_at_index * 15 <
                    lineOffset / 60 / PIXELS_PER_SECOND
                      ? 'opacity-50'
                      : '',
                  ]"
                  :expanded="false"
                  :mini="activity.ends_at_index - activity.starts_at_index <= 2"
                  :style="
                    getGridArea(
                      group.start_row + 2 + subgroupIndex,
                      group.start_row + 3 + subgroupIndex,
                      activity.starts_at_index + 1,
                      activity.ends_at_index + 1
                    )
                  "
                  class="z-content min-h-[150px]"
                  limit-width
                  @click="expandActivity(activity)"
                />
              </template>
              <div
                v-else
                :style="
                  getGridArea(
                    group.start_row + 2 + subgroupIndex,
                    group.start_row + 3 + subgroupIndex,
                    activity.starts_at_index + 1,
                    activity.ends_at_index + 1
                  )
                "
                class="rounded-lg bg-gray-100"
              />
            </template>
          </template>
        </template>

        <transition-group
          @enter="onEnterExpandActivity"
          @leave="onLeaveExpandActivity"
        >
          <!-- Selected Activity -->
          <schedule-activity-presenter
            v-for="activity in [expandedActivity].filter(Boolean)"
            :key="activity.id"
            :activity="expandedActivity"
            :data-id="activity.id"
            :mini="
              expandedActivity.ends_at_index -
                expandedActivity.starts_at_index <=
              2
            "
            class="absolute z-popup origin-center transform-gpu bg-blend-multiply shadow-2xl shadow-gray-700"
            expanded
            limit-width
            solid
            style="contain: paint"
            @click="expandActivity(null)"
            @open="emit('open', expandedActivity)"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { DateTime } from 'luxon'
import ScheduleActivityPresenter from '@/components/ui/ScheduleActivityPresenter.vue'
import { useDebounceFn, useThrottleFn } from '@vueuse/core'
import { gsap } from 'gsap'

const emit = defineEmits(['open', 'update:scroll'])

const props = defineProps({
  schedule: {
    type: Object,
    required: true,
  },
  activities: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  startTime: {
    type: DateTime,
    default: 0,
  },
  endTime: {
    type: DateTime,
    default: 0,
  },
  forceTimeLine: {
    type: Boolean,
    default: false,
  },
  typeTags: {
    type: Array,
    default: () => [],
  },
})

const store = inject('store')

// TODO: move hard coded values to props
const TICK_INTERVAL = 1000 * 60 // 1 minute
const TRACK_WIDTH = 150
const PIXELS_PER_SECOND = (TRACK_WIDTH + 8) / 15 / 60 // one track is 15 minutes long
const gapX = 8
const gapY = 16
const timeLineHeight = 57
const headerOffset = ref(0)
const lineOffset = ref(0)
const header = ref(null)
const body = ref(null)
const calendar = ref(null)
let tickerHandler = null

const expandedActivity = ref(null)

const blocksOnScreen = ref(Math.ceil((window.innerWidth ?? 0) / TRACK_WIDTH))
const times = computed(() => {
  let times = []
  if (props.loading) {
    return Array(blocksOnScreen.value).fill(DateTime.now())
  }
  if (!props.startTime || !props.endTime) {
    console.log('no start or end time')
    return times
  }
  let date = props.startTime

  while (date <= props.endTime) {
    times.push(date)
    date = date.plus({ minutes: 15 })
  }
  return times
})

const positionedActivities = computed(() => {
  if (props.loading) {
    return []
  }
  return props.activities.map((activity) => {
    activity.starts_at_index =
      activity.starts_at.diff(props.startTime, 'minutes').minutes / 15
    activity.ends_at_index =
      activity.ends_at.diff(props.startTime, 'minutes').minutes / 15
    return activity
  })
})

const selectedDayPreparedGroups = computed(() => {
  if (props.loading) {
    return [
      {
        groups: Array(6)
          .fill(0)
          .map((_, i) => {
            let start = 1
            return Array(blocksOnScreen.value)
              .fill(0)
              .map((_, i) => {
                return {
                  starts_at: start,
                  ends_at: (start += Math.floor(Math.random() * 4) + 1),
                }
              })
          }),
      },
    ]
  }

  // pre initialize data for future
  let meetupTypeTag = props.typeTags.find(
    (t) => t.name.toLowerCase() === 'meetup'
  )
  if (!meetupTypeTag) console.warn('Meetup type tag not found')

  // activities grouped by type tag
  let groups = {
    meetups: {
      name: 'Meetups',
      activities: [],
      order: 0,
    },
  }

  // position activities in groups
  positionedActivities.value.forEach((activity) => {
    // add any activities that need to be in separate groups
    if (activity.typeTag?.id === meetupTypeTag?.id) {
      groups.meetups.activities.push(activity)
    } else {
      // add activities based on their locations
      // remove stations for now
      // eslint-disable-next-line no-constant-condition
      let groupId = 'map_spot'
      let groupName = 'Map Spots'
      switch (activity.locationType) {
        case 'venue':
          groupId = 'venue-' + activity.venue.id
          groupName = activity.venue.name
          break
        case 'space':
          groupId = 'space-' + activity.space.id
          groupName = activity.space.name
          break
        case 'station':
          groupId = 'station-' + activity.station.id
          groupName = activity.station.name
          break
        default:
          break
      }

      if (!groups[groupId]) {
        groups[groupId] = {
          name: groupName,
          activities: [],
        }
      }
      groups[groupId].activities.push(activity)
    }
  })

  // clean up groups
  // add activities that overlap to separate groups
  Object.entries(groups).forEach(([key, value]) => {
    let newGroups = [[]]
    let count = 0
    // cycle through each item in the group
    value.activities.forEach((item) => {
      let newGroupNeeded = true
      count++

      // cycle through each new group
      newGroups.every((newGroup) => {
        if (newGroup.length === 0) {
          newGroup.push(item)
          newGroupNeeded = false
          return false
        } else {
          let overlaps = false
          // check if item overlaps with any items in the new group
          newGroup.forEach((newGroupItem) => {
            if (
              item.starts_at_index < newGroupItem.ends_at_index &&
              item.ends_at_index > newGroupItem.starts_at_index
            ) {
              overlaps = true
            }
          })

          // if any existing group is suitable, add the item to it
          if (!overlaps) {
            newGroup.push(item)
            newGroupNeeded = false
            return false
          } else {
            return true
          }
        }
      })

      // if no existing group is suitable, create a new one
      if (newGroupNeeded) {
        newGroups.push([item])
      }
    })

    // add new groups to the groups object and sort by items count
    groups[key].groups = newGroups.sort((a, b) => b.length - a.length)
    groups[key].count = count
  })

  let rows = 0
  return Object.values(groups)
    .filter((g) => g.groups.length > 0 && g.groups.flat().length > 0)
    .sort((a, b) => {
      // if group has order property, sort by it
      if (a.order !== undefined && b.order !== undefined) {
        return a.order - b.order
      } else if (a.order !== undefined) {
        return -1
      } else if (b.order !== undefined) {
        return 1
      }

      return b.count - a.count
    })
    .map((g) => {
      g.start_row = rows
      rows += g.groups.length
      g.end_row = rows

      // final cleanup, fill empty spaces
      g.groups = g.groups.map((group) => {
        let newGroup = []
        let lastEnd = 1

        // sort by start time
        group
          .sort((a, b) => a.starts_at_index - b.starts_at_index)
          .forEach((item) => {
            let currentTime = times.value[0].plus({ minutes: lastEnd * 15 })
            // fill empty spaces
            if (item.starts_at_index - lastEnd > 4) {
              newGroup.push({
                type: 'filler',
                starts_at_index: lastEnd,
                ends_at_index: item.starts_at_index,
                next_name: item.name,
                next_id: item.id,
                next_position_x:
                  item.starts_at_index * 15 * 60 * PIXELS_PER_SECOND,
                time: Math.round(
                  item.starts_at.diff(currentTime, 'hours').hours
                ),
              })
            }
            newGroup.push(item)
            lastEnd = item.ends_at_index
          })

        return newGroup
      })

      return g
    })
})

watch(selectedDayPreparedGroups, () => {
  runTicker()
  expandActivity(null)
})

watch(
  () => props.loading,
  () => {
    if (!props.loading) {
      setHeaderOffset()
      runTicker()
      requestAnimationFrame(() => {
        body.value.scrollLeft = lineOffset.value - window.innerWidth / 2
      })
    } else {
      body.value.scrollLeft = 0
    }
  }
)

const setHeaderOffset = () => {
  headerOffset.value =
    +calendar.value.getBoundingClientRect().top +
    document.documentElement.scrollTop
  blocksOnScreen.value = Math.ceil((window.innerWidth ?? 0) / TRACK_WIDTH)
}

const bodyHeight = ref(0)
const setBodyHeight = () => {
  bodyHeight.value =
    window.innerHeight - +calendar.value.getBoundingClientRect().top
}

onMounted(() => {
  setHeaderOffset()

  // setBodyHeight()
  // window.addEventListener('resize', () => {
  //   setHeaderOffset()
  //   setBodyHeight()
  // })

  // set header top to calendar top
  tickerScheduler()
  body.value.scrollLeft = lineOffset.value - window.innerWidth / 2
})

// on screen resize, run headerOffset.value = +calendar.value.getBoundingClientRect().top + document.documentElement.scrollTop

onBeforeUnmount(() => {
  clearInterval(tickerHandler)
})

function tickerScheduler() {
  runTicker()
  tickerHandler = setTimeout(() => {
    tickerScheduler()
  }, TICK_INTERVAL)
}

function runTicker() {
  if (props.loading) {
    return
  }
  const firstTime = times.value[0]
  let now = DateTime.now()

  now = now.setZone(firstTime.zoneName)
  if (props.forceTimeLine) {
    now = now.set({
      day: firstTime.day,
      month: firstTime.month,
      year: firstTime.year,
      hour: firstTime.hour + 3,
      minute: firstTime.minute + 30,
      second: 0,
      millisecond: 0,
    })
  }
  if (now < firstTime || now > times.value[times.value.length - 1]) {
    console.log('current time is outside of schedule, line offset hidden')
    lineOffset.value = -1000
    return false
  }

  const diff = now.diff(firstTime, 'minutes').minutes
  lineOffset.value = diff * PIXELS_PER_SECOND * 60
}

function getGridArea(rowStart, rowEnd, colStart, colEnd) {
  return (
    'grid-area: ' +
    +rowStart +
    ' / ' +
    +colStart +
    ' / ' +
    +rowEnd +
    ' / ' +
    +colEnd +
    ';'
  )
}

function getStartSection(group) {
  let activities = group.groups.flat(3)
  let groupEnd = activities[0].starts_at_index
  activities.forEach((item, index) => {
    if (groupEnd > item.starts_at_index) {
      groupEnd = item.starts_at_index
    }
  })
  return groupEnd
}

function getEndSection(group) {
  let activities = group.groups.flat(3)
  let groupEnd = activities[0].ends_at_index
  activities.forEach((item, index) => {
    if (groupEnd < item.ends_at_index) {
      groupEnd = item.ends_at_index
    }
  })
  return groupEnd
}

function getGroupName(group) {
  if (group.length === 0) {
    throw new Error('group is empty')
  }
  return group.name
}

const gridColumns = computed(() => {
  if (props.loading) return blocksOnScreen.value
  else return times.value.length
})

const expandedActivityRect = reactive({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
})

const device = inject('device')

function expandActivity(activity) {
  if (activity) {
    if (device === 'desktop') expandedActivity.value = activity
    else
      emit('open', activity)
  } else
    expandedActivity.value = null
  // if (expandedActivity.value?.id === activity?.id) {
  //   expandedActivity.value = null
  //   expandedActivityRect.top = 0
  //   expandedActivityRect.left = 0
  //   expandedActivityRect.width = 0
  //   expandedActivityRect.height = 0
  //   return
  // }
  // expandedActivity.value = activity
  // // if (activity === null) return
  // const el = document
  //   .getElementById('activity-' + activity.id)
  //   .getBoundingClientRect()
  // const bodyRect = body.value.getBoundingClientRect()
  // expandedActivityRect.top = el.top - bodyRect.top + body.value.scrollTop
  // expandedActivityRect.left = el.left - bodyRect.left + body.value.scrollLeft
  // expandedActivityRect.width = el.width
  // // expandedActivityRect.height = el.height
  // //
  // requestAnimationFrame(() => {
  //   expandedActivity.value = activity
  //   requestAnimationFrame(() => {
  //     expandedActivityRect.top = expandedActivityRect.top - 16
  //     expandedActivityRect.left = expandedActivityRect.left - 16
  //     // expandedActivityRect.width = expandedActivityRect.width + 32
  //     // expandedActivityRect.height = 300
  //   })
  // })
}

const scrollX = ref(0)
const scrollY = ref(0)
const gridPosition = reactive({
  colStart: 0,
  colEnd: blocksOnScreen.value,
  rowStart: 0,
  rowEnd: 20,
})
const hideThrottle = ref(false)

function scrollToX(x) {
  hideThrottle.value = true
  gridPosition.colStart = 0
  gridPosition.colEnd = times.value.length
  requestAnimationFrame(() => {
    body.value.scrollTo({
      left: x - body.value.clientWidth / 4,
      top: body.value.scrollTop,
      behavior: 'smooth',
    })
    setTimeout(() => {
      hideThrottle.value = false
    }, 500)
  })
}

function syncScroll(e) {
  emit(
    'update:scroll',
    e.target.scrollLeft / (e.target.scrollWidth - e.target.clientWidth)
  )

  if (hideThrottle.value) {
    console.log('throttled')
    return
  }

  scrollX.value = e.target.scrollLeft
  scrollY.value = e.target.scrollTop

  gridPosition.colStart = Math.max(
    0,
    Math.floor(e.target.scrollLeft / (TRACK_WIDTH + gapX))
  )
  gridPosition.colEnd = Math.min(
    times.value.length,
    gridPosition.colStart +
      Math.ceil((window.innerWidth ?? 0) / (TRACK_WIDTH + gapX))
  )

  // remove time line height and one gap
  gridPosition.rowStart = Math.max(
    0,
    Math.floor(
      (e.target.scrollTop - timeLineHeight - gapY) / (TRACK_WIDTH + gapY)
    )
  )
  gridPosition.rowEnd = Math.min(
    selectedDayPreparedGroups.value.map((g) => g.groups).flat(1).length,
    gridPosition.rowStart +
      Math.ceil((window.innerHeight ?? 0) / (TRACK_WIDTH + gapY))
  )
}

const syncScrollThrottled = useThrottleFn(syncScroll, 50)
const syncScrollDebounced = useDebounceFn(syncScroll, 100)

function syncScrollThrottledDebounce(e) {
  syncScrollThrottled(e)
  syncScrollDebounced(e)
}

function onEnterExpandActivity(el, done) {
  const id = el.dataset.id
  const rect = document.getElementById('activity-' + id).getBoundingClientRect()
  const bodyRect = body.value.getBoundingClientRect()
  // create a clone of the element that will be expanded
  const box = {
    top: rect.top - bodyRect.top + body.value.scrollTop,
    left: rect.left - bodyRect.left + body.value.scrollLeft,
    width: rect.width,
    height: rect.height,
  }

  // const minWidth = Math.min(Math.max(320, box.width + 32), window.innerWidth)
  const minWidth = Math.max(320, box.width + 32)

  gsap.fromTo(
    el,
    {
      top: box.top,
      left: box.left,
      width: box.width,
      height: box.height,
      transformOrigin: 'center center',
    },
    {
      top: box.top - 16,
      left: box.left - (minWidth - box.width) / 2,
      width: minWidth,
      height: 'auto',
      maxHeight: 300,
      duration: 0.3,
      onComplete: done,
      ease: 'power2.inOut',
      transformOrigin: 'center center',
    }
  )
}

function onLeaveExpandActivity(el, done) {
  const id = el.dataset.id
  const rect = document
    .getElementById('activity-' + id)
    ?.getBoundingClientRect()

  // handle the case where the element is not in the dom anymore
  if (!rect) {
    done()
    return
  }

  const bodyRect = body.value.getBoundingClientRect()
  const elRect = el.getBoundingClientRect()

  const box = {
    top: rect.top - bodyRect.top + body.value.scrollTop,
    left: rect.left - bodyRect.left + body.value.scrollLeft,
    width: rect.width,
    height: rect.height,
  }

  // animate back to the original position
  gsap.fromTo(
    el,
    {
      top: elRect.top - bodyRect.top + body.value.scrollTop,
      left: elRect.left - bodyRect.left + body.value.scrollLeft,
      width: elRect.width,
      height: elRect.height,
    },
    {
      top: box.top,
      left: box.left,
      width: box.width,
      height: box.height,
      duration: 0.3,
      onComplete: done,
      ease: 'power2.inOut',
    }
  )
}
</script>

<style scoped>
.bg-lines {
  background-image: repeating-linear-gradient(
    60deg,
    #f7f8fc,
    #f7f8fc 30px,
    #eaeaf5 30px,
    #eaeaf5 50px
  );
}

.vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  overflow: hidden;
}
</style>
