<template>
  <div class="w-full ml:rounded-t-2xl">
    <input
      ref="fileInput"
      accept="image/*"
      class="hidden"
      type="file"
    />
    <background-image-component
      :image="section.banner"
      class="absolute left-0 right-0 top-0 flex h-[480px] flex-col items-center"
      fallback-class="bg-gray-200"
      fallback-hidden
      tag="div"
    >
      <div
        class="absolute inset-0 bg-gradient-to-b from-transparent from-70% to-slate-100 to-100%"
      />
      <div
        v-if="edit"
        class="absolute right-5 top-5 flex flex-col gap-1"
      >
        <button
          class="bg-blue-primary bg-opacity-75 px-2 py-1 text-white"
          @click="
            changeImage((image) => {
              section.banner = image
            })
          "
        >
          <font-awesome-icon icon="image" />
          {{ section.banner ? 'Change' : 'Set' }} Banner
        </button>
        <button
          v-if="section.banner"
          class="bg-blue-primary bg-opacity-75 px-2 py-1 text-white"
          @click="section.banner = null"
        >
          <font-awesome-icon icon="trash" />
          Remove Banner
        </button>
      </div>
      <VDropdown
        v-if="edit"
        class="absolute left-5 top-5"
        placement="bottom"
      >
        <button class="bg-blue-primary bg-opacity-75 px-2 py-1 text-white">
          <font-awesome-icon icon="palette" />
          Change Theme
        </button>
        <template #popper>
          <div class="flex max-w-[300px] gap-2 overflow-x-scroll px-2 py-3">
            <button
              v-for="color in themeColors"
              :key="color"
              :class="`bg-${color}-800`"
              class="h-20 w-20 shrink-0 rounded-full"
              @click="
                () => {
                  theme.textTags = `text-${color}-800`
                  theme.textDetails = `text-${color}-800`
                  theme.backgroundDetails = `bg-${color}-100`
                  theme.iconsMain = `text-${color}-800`
                  theme.backgroundGradient = `bg-${color}-950`
                }
              "
            />
          </div>
        </template>
      </VDropdown>
      <div class="flex h-72 flex-col items-center justify-center">
        <picture
          v-if="section.logo || edit"
          :class="{
            'outline outline-2 outline-blue-primary hover:outline-blue-neon':
              edit,
          }"
          class="group relative drop-shadow-2xl"
        >
          <image-component
            :image="section.logo"
            alt="logo"
            class="h-40"
          />
          <template v-if="edit">
            <div
              :class="{
                'opacity-0': section.logo,
              }"
              class="absolute inset-0 flex flex-col items-center justify-center gap-1 bg-blue-neon bg-opacity-75 text-white duration-100 group-hover:opacity-100"
            >
              <button
                class="w-full max-w-[150px] bg-blue-primary"
                @click="
                  changeImage((image) => {
                    section.logo = image
                  })
                "
              >
                {{ section.logo?.path ? 'Change Logo' : 'Add Logo' }}
              </button>
              <button
                v-if="section.logo"
                class="w-full max-w-[150px] bg-blue-primary bg-opacity-50"
                @click="section.logo = null"
              >
                Remove Logo
              </button>
            </div>
            <div
              class="absolute -left-0.5 top-[calc(100%+2px)] origin-top-left scale-0 bg-blue-neon px-1 py-0.5 font-heading text-sm font-normal text-white opacity-0 duration-100 group-hover:scale-100 group-hover:opacity-100"
            >
              Logo
            </div>
          </template>
        </picture>
      </div>
    </background-image-component>

    <section
      :class="[
        theme?.textPrimary ? theme?.textPrimary : 'text-black',
        theme?.backgroundPrimary ? theme?.backgroundPrimary : 'bg-white ',
        edit ? 'group/section' : '',
      ]"
      class="relative mt-72 flex flex-col justify-between gap-12 px-0 py-5 md:p-20 ml:rounded-t-2xl"
    >
      <v-button
        v-if="enableEdit"
        class="absolute right-10 top-2 z-20 rounded-full bg-violet-100 px-10 py-2 text-sm md:top-4"
        variant="secondary"
        @click="emit('update:edit', !edit)"
      >
        {{ edit ? 'Save Changes' : 'Edit this page' }}
      </v-button>
      <div
        class="flex flex-col justify-between gap-12 md:flex-col-reverse xl:flex-row"
      >
        <div class="flex grow flex-col gap-6 px-5 sm:px-10 md:px-0 xl:w-0">
          <h5
            :class="theme?.textTags ? theme.textTags : 'text-blue-neon'"
            class="-mb-2 font-heading text-sm uppercase"
          >
            {{ selectedEvent?.name }} HIGHLIGHTS
          </h5>
          <showcase-text-element
            v-model="section.name"
            :class="theme?.textPrimary ? theme.textPrimary : 'text-dark-grey'"
            class="font-heading text-[40px] font-bold"
            tag="h1"
          />
          <showcase-text-element
            v-model="section.description"
            :class="
              theme?.textSecondary ? theme.textSecondary : 'text-paragraph'
            "
            class="font-sans text-base font-normal"
            tag="p"
          />

          <p>
            <span
              v-for="(tag, index) in props.section.tags"
              :key="index"
              class="font-sans text-base font-normal text-paragraph"
            >
              {{ tag.name }}
              <template v-if="index < props.section.tags.length - 1">
                ,
              </template>
            </span>
          </p>
        </div>

        <div
          v-if="props.section.image_right?.path || edit"
          class="relative grow xl:max-w-[50%]"
        >
          <div
            v-if="edit"
            class="absolute left-1/2 top-1/2 z-floating -translate-x-1/2 -translate-y-1/2 flex-col gap-2"
          >
            <button
              class="bg-blue-neon px-2 py-1 text-sm text-white outline outline-blue-primary"
              @click="changeImage((image) => (section.image_right = image))"
            >
              {{ section.image_right?.path ? 'Change Image' : 'Add Image' }}
            </button>
            <button
              v-if="section.image_right"
              class="bg-blue-neon px-2 py-1 text-sm text-white outline outline-blue-primary"
              @click="section.image_right = null"
            >
              Clear
            </button>
          </div>
          <image-component
            v-if="section.image_right?.path"
            :image="section.image_right"
            alt="restaurant photo"
            class="h-full max-h-[313px] w-full min-w-[300px] object-cover brightness-90 md:rounded-xl"
          />
        </div>
      </div>
      <transition-list
        class="flex w-full flex-wrap justify-center gap-4"
        enter-active-class="transition duration-300 ease-in-out"
        enter-from-class="opacity-0 scale-0"
        leave-active-class="absolute transition duration-300 ease-in-out"
        leave-to-class="opacity-0 scale-0"
        move-class="transition duration-300 ease-in-out"
        tag="div"
      >
        <showcase-badge
          v-for="(badge, i) in section.badges"
          :key="badge.type"
          :badge="badge"
          :theme="theme"
          variant="mini"
          @remove="removeBadge(badge)"
        />
        <VDropdown
          v-if="edit"
          key="add-badge"
        >
          <showcase-badge
            :badge="{ type: 'add' }"
            :theme="theme"
            variant="mini"
          />
          <template #popper>
            <div
              class="grid max-h-[300px] grid-cols-3 gap-3 overflow-y-scroll px-2 py-3"
            >
              <showcase-badge
                v-for="type in badgeTypes.slice().sort((a, b) =>
                  sortNotInList(
                    a,
                    b,
                    section.badges.map((b) => b.type),
                    (a, b) => a.localeCompare(b)
                  )
                )"
                :key="type"
                :badge="{ type }"
                :disabled="section.badges.find((b) => b.type === type)"
                :theme="theme"
                example
                variant="mini"
                @click="addBadge(type)"
              />
              <showcase-badge
                :badge="{ type: 'coming-soon' }"
                :theme="theme"
                disabled
                example
                variant="mini"
              />
            </div>
          </template>
        </VDropdown>
      </transition-list>
    </section>
    <section
      :class="[
        theme?.backgroundsSecondary
          ? theme.backgroundsSecondary
          : 'bg-slate-950',
        theme?.textPrimary ? theme.textPrimary : 'text-white',
      ]"
      class="flex flex-col font-sans font-normal"
    >
      <div
        class="container mx-auto flex w-fit flex-col items-center gap-5 px-0 py-6 md:flex-row md:gap-20 md:px-10"
      >
        <div class="flex items-center gap-3 text-xs md:text-base">
          <pin-icon
            :class="
              theme?.iconsSecondary ? theme?.iconsSecondary : 'text-white'
            "
          />
          We're at {{ selectedEvent?.name }}
          <span v-if="section.events?.length > 1">
            +
            <span class="underline">{{ section?.events?.length }} more</span>
          </span>
        </div>
        <hr
          v-if="featureFlags.AddToFavorite"
          class="hidden h-[2px] w-[36px] rotate-90 stroke-paragraph lg:flex"
        />
        <div
          v-if="featureFlags.AddToFavorite"
          class="flex items-center gap-3"
        >
          <heart-icon
            :class="
              theme?.iconsSecondary ? theme?.iconsSecondary : 'text-white'
            "
          />
          Add Favourites
        </div>
      </div>
      <hr />
      <div
        v-if="section.categories?.length"
        class="flex items-center justify-center px-4 py-4 md:px-10 lg:px-14"
      >
        <div class="flex gap-8 overflow-scroll px-4 md:max-w-[80%]">
          <div
            v-for="(item, index) in section.categories"
            :key="index"
            class="flex flex-col gap-3"
          >
            <background-image-component
              :class="[
                theme?.backgroundSecondaryLight
                  ? theme.backgroundSecondaryLight
                  : 'bg-slate-900',
                'h-[96px] w-[96px] rounded-full',
              ]"
              :src="item.image.path"
            />

            <span
              :class="[
                theme?.textSecondary ? theme.textSecondary : 'text-white',
              ]"
              class="text-center font-sans text-base font-normal"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useActiveElement } from '@vueuse/core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { sortNotInList } from '@/utils/array'
import featureFlags from '@/utils/composables/flags'
import useConventionList from '@/utils/composables/conventionList'

import PinIcon from '@/icons/PinIcon.vue'
import HeartIcon from '@/icons/HeartIcon.vue'
import VButton from '@/components/buttons/VButton.vue'

import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '../BackgroundImageComponent.vue'

import TransitionList from '@/components/layout/TransitionList.vue'
import ShowcaseBadge from '@/components/ui/showcase/ShowcaseBadge.vue'
import ShowcaseTextElement from '@/components/ui/showcase/ShowcaseTextElement.vue'

const emit = defineEmits(['update:edit'])
const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  mini: {
    type: Boolean,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: Object,
    required: true,
  },
})

const activeElement = useActiveElement()

const { selected: selectedEvent } = useConventionList()

const testImage = ref({
  path: 'https://picsum.photos/seed/picsum/1920/1080',
})

const fileInput = ref(null)
const badgeTypes = ['exclusives', 'activities', 'events', 'offers', 'news']
const themeColors = ['blue', 'orange', 'red', 'slate']

function removeBadge(badge) {
  const index = props.section.badges.findIndex((b) => b.type === badge.type)
  props.section.badges.splice(index, 1)
}

function addBadge(type) {
  props.section.badges.push({ type })
}

function changeImage(callback) {
  if (!fileInput.value) throw new Error('File upload element not mounted.')
  fileInput.value.click()
  fileInput.value.onchange = (e) => {
    const file = e.target.files[0]
    fileInput.value.value = null
    callback({
      file: file,
      path: URL.createObjectURL(file),
    })
  }
}
</script>

<style scoped>
.gradient {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
</style>
