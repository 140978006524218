<template>
  <abstract-card-with-scroll-items
    :banner="getBackgroundImage"
    :description="shop.description"
    :items="shop.products"
    :link="`/events/${eventSlug}/shops/${shop.slug}`"
    :name="shop.name"
    :tags="shop.tags?.map((t) => t.name)"
  >
    <template #overlay="{ opacity }">
      <div
        :style="{ opacity: opacity }"
        class="p-4 text-2xl font-medium text-white"
      >
        {{ shop.name }}
      </div>
      <div
        v-if="shop.percentile > 0"
        class="flex h-full w-full items-end rounded-md pt-6 text-white"
      >
        <div
          class="flex flex-row items-center gap-1 bg-slate-900 px-2 py-1 text-xs"
        >
          Top {{ shop.percentile }}%
        </div>
        <div
          v-if="shop.overall_rating"
          class="flex flex-row items-center gap-1 rounded-tr-lg px-2 py-1"
          style="background-color: #ff390e"
        >
          <div class="text-xs">
            {{ shop.overall_rating }}
          </div>
          <font-awesome-icon
            class="text-xs"
            icon="star"
          />
        </div>
      </div>
    </template>
    <template #info-top>
      <div v-if="shop.activities?.length > 0">
        <span
          class="text-sm"
          style="color: #bfc6cd"
        >
          {{ shop.activities?.length }} activities
        </span>
      </div>
    </template>
    <template #info-additions>
      <!--      <div class="font-heading text-sm text-slate-400">-->
      <!--        <span v-if="state?.latitude && state?.longitude && shop.latitude && shop.longitude">-->
      <!--          {{ getDistance(shop.latitude, shop.longitude) }}-->
      <!--        </span>-->
      <!--      </div>-->
    </template>
    <template #other-info>
      <div
        class="-mt-4 mb-4 flex gap-x-3 font-sans text-sm font-normal text-light-grey-100 md:-mt-2 md:mb-0"
      >
        <span
          v-if="showLocation"
          class="flex md:hidden"
        >
          {{ getLocationType }} {{ getLocationName }}
        </span>
        <template
          v-if="showLatitudeLongitude"
          class="flex md:hidden"
        >
          <span>•</span>
          <span>
            {{ getDistance(shop.latitude, shop.longitude) }} meters away
          </span>
        </template>
        <template v-if="shop.activities?.length">
          <span class="hidden md:flex">•</span>
          <span class="hidden md:flex">
            {{ shop.activities?.length }} scheduled activities
          </span>
        </template>
      </div>

      <div
        v-if="showLocation"
        class="hidden w-fit flex-col gap-2 rounded-lg bg-slate-100 px-4 py-2 font-heading text-2xl font-bold text-blue-primary md:flex"
      >
        <span class="text-sm text-light-grey">
          {{ getLocationType }}
        </span>
        <span>
          {{ getLocationName }}
        </span>
      </div>
    </template>
    <template #item="{ item }">
      <item-card
        :event-slug="eventSlug"
        :is-menu-item="false"
        :item="item"
        class="mx-auto"
      />
    </template>
  </abstract-card-with-scroll-items>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import AbstractCardWithScrollItems from '@/components/ui/AbstractCardWithScrollItems.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'
import { calculateDistance } from '@/plugins/CalculateDistance'
import { useUserLocation } from '@/utils/composables/location'

const props = defineProps({
  shop: {
    type: Object,
    required: true,
  },
  eventSlug: {
    type: String,
    default: null,
  },
})

const getBackgroundImage = computed(() => {
  return props.shop?.icon?.path ?? '/images/placeholder_product.jpg'
})

// TODO: Hey Nazar, please refactor so this pulls in the location information (type and name) necessary to showcase the dynamic location of the shop

const getLocationType = computed(() => {
  return props.shop?.location_type ?? null
})

const getLocationName = computed(() => {
  return props.shop?.location_name ?? null
})

const location = useUserLocation()

const showLatitudeLongitude = computed(() => {
  return !!(
    location.value?.latitude &&
    location.value?.longitude &&
    props.shop.latitude &&
    props.shop.longitude
  )
})

const showLocation = computed(() => {
  return !!props.shop?.location_type
})

const getDistance = (latitude, longitude) => {
  return calculateDistance(
    location.value?.latitude,
    location.value?.longitude,
    latitude,
    longitude
  )
}
</script>

<style scoped></style>
