<template>
  <event-browse-layout>
    <template #section-title>
      <slot name="section-title">
        <h1
          class="select-none px-0 text-left font-heading text-2xl font-normal text-paragraph md:px-10 md:py-4 md:text-3xl lg:py-2"
        >
          <slot name="section-title-name" />
        </h1>
      </slot>
    </template>
    <template #sub-stick-header>
      <div class="flex select-none flex-col gap-10 px-1 pb-4 md:px-10 md:pb-10">
        <div class="mx-auto flex w-full flex-row justify-between">
          <div class="flex flex-row flex-nowrap justify-center gap-1 md:gap-4">
            <a
              v-for="tab in tabs"
              :key="tab.value + '-tab'"
              :class="{
                'bg-dark-menu text-white': tab.value === selectedTab,
                'cursor-pointer text-paragraph hover:bg-gray-300':
                  tab.value !== selectedTab,
              }"
              :href="`#${tab.value}`"
              class="z-20 col-span-2 select-none rounded-xl px-3 py-3 text-center font-heading text-sm font-bold md:px-8 md:text-lg"
            >
              {{ tab.name }}
            </a>
          </div>
          <!-- TODO: add filtering and search -->
          <div
            v-if="false"
            class="flex gap-8"
          >
            <div
              class="flex cursor-pointer select-none items-center justify-center gap-6"
            >
              <span class="hidden md:flex">Search</span>
              <svg
                fill="none"
                height="25"
                viewBox="0 0 24 25"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M11.1 5.3C7.62061 5.3 4.8 8.12061 4.8 11.6C4.8 15.0794 7.62061 17.9 11.1 17.9C14.5794 17.9 17.4 15.0794 17.4 11.6C17.4 8.12061 14.5794 5.3 11.1 5.3ZM3 11.6C3 7.12649 6.62649 3.5 11.1 3.5C15.5735 3.5 19.2 7.12649 19.2 11.6C19.2 16.0735 15.5735 19.7 11.1 19.7C6.62649 19.7 3 16.0735 3 11.6Z"
                  fill="#47474F"
                  fill-rule="evenodd"
                />
                <path
                  clip-rule="evenodd"
                  d="M15.5486 16.0486C15.9001 15.6971 16.4699 15.6971 16.8214 16.0486L20.7364 19.9636C21.0879 20.3151 21.0879 20.8849 20.7364 21.2364C20.3849 21.5879 19.8151 21.5879 19.4636 21.2364L15.5486 17.3214C15.1971 16.9699 15.1971 16.4001 15.5486 16.0486Z"
                  fill="#47474F"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
            <div
              class="flex cursor-pointer select-none items-center justify-center gap-6"
            >
              <span class="hidden md:flex">Filters</span>
              <svg
                fill="none"
                height="25"
                viewBox="0 0 24 25"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 6.625V4.5M12.5 6.625C11.3264 6.625 10.375 7.57639 10.375 8.75C10.375 9.92361 11.3264 10.875 12.5 10.875M12.5 6.625C13.6736 6.625 14.625 7.57639 14.625 8.75C14.625 9.92361 13.6736 10.875 12.5 10.875M6.125 19.375C7.29861 19.375 8.25 18.4236 8.25 17.25C8.25 16.0764 7.29861 15.125 6.125 15.125M6.125 19.375C4.95139 19.375 4 18.4236 4 17.25C4 16.0764 4.95139 15.125 6.125 15.125M6.125 19.375V21.5M6.125 15.125V4.5M12.5 10.875V21.5M18.875 19.375C20.0486 19.375 21 18.4236 21 17.25C21 16.0764 20.0486 15.125 18.875 15.125M18.875 19.375C17.7014 19.375 16.75 18.4236 16.75 17.25C16.75 16.0764 17.7014 15.125 18.875 15.125M18.875 19.375V21.5M18.875 15.125V4.5"
                  stroke="#47474F"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #browse-page>
      <slot />
    </template>
  </event-browse-layout>
</template>

<script setup>
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'
import { onBeforeUnmount, onMounted } from 'vue'

const emit = defineEmits(['update:selectedTab'])

const props = defineProps({
  // Array of tabs. e.g. [{ name: 'Meals', value: 'meals' }, { name: 'Shops', value: 'shops' }]
  tabs: {
    type: Array,
    required: true,
  },
  // current selected tab index.
  selectedTab: {
    type: String,
    required: true,
  },
})

function selectActiveHash() {
  const hash = window.location.hash?.replace('#', '')
  if (hash) {
    emit('update:selectedTab', hash)
  }
}

onMounted(() => {
  selectActiveHash()

  window.addEventListener('hashchange', selectActiveHash)
})

onBeforeUnmount(() => {
  window.removeEventListener('hashchange', selectActiveHash)
})
</script>

<style scoped></style>
