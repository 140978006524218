<template>
  <event-browse-layout>
    <template #browse-page>
      <section
        v-if="selectedEvent"
        class="flex flex-col gap-[90px] px-0 pb-[90px]"
      >
        <meet-hero
          :event="selectedEvent"
          :find-preference="findPreference"
          :type="pageType"
          @change-page-type="pageType = $event"
        />
        <div class="mx-auto flex w-full max-w-screen-xl flex-col">
          <div class="relative flex w-full flex-col px-4 xl:px-0">
            <span
              class="text-center font-poppins text-sm font-bold uppercase text-slate-400"
            >
              {{ pageType }}
            </span>
            <h2
              class="mb-8 flex w-full select-none flex-col gap-2 text-center font-poppins text-xl font-bold sm:text-2xl lg:text-3xl"
            >
              Special guests
              <a
                v-if="featureFlags.LinkToBrowsePage"
                :style="{
                  color: selectedEvent?.palette?.primary
                    ? selectedEvent.palette.primary
                    : '#000000',
                }"
                class="right-4 font-poppins text-base font-bold md:absolute xl:right-0"
                href=""
              >
                See all
              </a>
            </h2>
          </div>

          <slide-up-popup
            :is-popup-open="selectedGuest != null"
            :title="selectedGuest?.name + ' Details'"
            @close-popup="selectedGuest = null"
          >
            <div
              v-if="selectedGuest"
              class="flex flex-col items-center"
            >
              <img
                v-if="selectedGuest.image?.path"
                :alt="selectedGuest.name + ' picture'"
                :src="selectedGuest.image?.path"
                class="mb-2 h-48 w-48 rounded-full object-cover"
              />
              <!-- user icon -->
              <svg
                v-else
                class="mb-2 h-48 w-48 rounded-full bg-gray-200 p-4 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 14s-5 2-5 5v1h10v-1c0-3-5-5-5-5z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M12 12a3 3 0 100-6 3 3 0 000 6z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
              <div class="flex flex-col items-center text-center">
                <h5>{{ selectedGuest.name }}</h5>
                <p>
                  {{ selectedGuest.bio }}
                </p>
              </div>
            </div>
          </slide-up-popup>

          <div
            v-if="guests"
            class="mx-auto flex max-w-full gap-8 overflow-scroll pb-5"
          >
            <template v-if="findPreference">
              <special-guest-card
                v-for="(guest, guestID) in guests.slice(0, 2)"
                :key="guestID"
                :class="guestID !== 0 ? 'mr-8' : 'mx-8'"
                :event="selectedEvent"
                :guest="guest"
                :palette="selectedEvent.palette"
                background-image
                @select-guest="selectedGuest = guest"
              />
              <div
                class="mr-8 flex flex w-full min-w-[280px] max-w-[300px] flex-col items-center justify-center rounded-lg border border-dashed border-slate-300 p-6"
              >
                <h5
                  class="mb-4 text-center font-poppins text-lg font-bold text-blue-muted"
                >
                  Missing data
                </h5>
                <p
                  class="mb-2 text-center font-poppins text-lg font-normal text-blue-muted"
                >
                  Please fill out your preferences to see more personalized
                  special guests.
                </p>
                <button class="flex items-center gap-2 py-4 text-blue-primary">
                  <font-awesome-icon
                    class="h-4 w-4 shrink-0"
                    icon="plus"
                  />
                  Add preferences
                </button>
              </div>
            </template>
            <template v-else>
              <special-guest-card
                v-for="(guest, guestID) in guests.slice(0, 6)"
                :key="guestID"
                :event="selectedEvent"
                :guest="guest"
                :loading="!guestsFetched"
                background-image
                class="shrink-0 first:ml-8 last:mr-8"
                @select-guest="selectedGuest = guest"
              />
            </template>
          </div>
        </div>

        <div
          v-if="groupsFetched && groups?.length"
          class="mx-auto flex w-full max-w-screen-xl flex-col px-0 sm:px-2"
        >
          <div class="relative flex w-full flex-col px-4 xl:px-0">
            <span
              class="text-center font-poppins text-sm font-bold uppercase text-slate-400"
            >
              {{ pageType }}
            </span>
            <h2
              class="mb-8 flex w-full select-none flex-col gap-2 text-center font-poppins text-xl font-bold sm:text-2xl lg:text-3xl"
            >
              Groups

              <a
                v-if="featureFlags.LinkToBrowsePage"
                :style="{
                  color: selectedEvent?.palette?.primary
                    ? selectedEvent.palette.primary
                    : '#000000',
                }"
                class="right-4 font-poppins text-base font-bold md:absolute xl:right-0 xl:right-0"
                href=""
              >
                See all
              </a>
            </h2>
          </div>
          <template v-if="groupsFetched && groups?.length">
            <div class="flex max-w-full overflow-scroll lg:justify-center">
              <template v-if="findPreference">
                <div
                  v-for="(group, index) in groups.slice(0, 2) as UserGroup[]"
                  :key="group.id"
                  :class="index !== 0 ? 'mr-10' : 'mx-10'"
                  class="flex w-[192px] shrink-0 flex-col items-center gap-1 text-center"
                >
                  <image-component
                    :image="group.avatar"
                    class="h-[155px] w-[192px] w-full shrink-0 rounded-lg object-cover"
                  />
                  <h5 class="font-sans text-base font-bold text-black">
                    {{ group.name }}
                  </h5>
                  <p class="font-sourcePro text-sm font-normal text-slate-600">
                    {{ group.conventions_count ?? '0' }} Cons Attended
                  </p>
                </div>
                <div
                  class="mr-8 flex flex h-[205px] w-full min-w-[280px] max-w-[300px] flex-col items-center justify-center rounded-lg border border-dashed border-slate-300 p-6"
                >
                  <h5
                    class="mb-2 text-center font-poppins text-lg font-bold text-blue-muted"
                  >
                    Missing data
                  </h5>
                  <p
                    class="mb-2 text-center font-poppins text-base font-normal text-blue-muted"
                  >
                    Please fill out your preferences to see more personalized
                    special guests.
                  </p>
                  <button
                    class="flex items-center gap-2 py-2 text-blue-primary"
                  >
                    <font-awesome-icon
                      class="h-4 w-4 shrink-0"
                      icon="plus"
                    />
                    Add preferences
                  </button>
                </div>
              </template>
              <template v-else>
                <Link
                  v-for="(group, index) in groups as UserGroup[]"
                  :key="group.id"
                  :class="index !== 0 ? 'mr-10' : 'mx-10'"
                  :href="`/groups/${group.id}`"
                  class="group flex w-[192px] shrink-0 flex-col items-center gap-1 text-center"
                >
                  <image-component
                    :image="group.avatar"
                    class="h-[155px] w-[192px] w-full shrink-0 rounded-lg object-cover group-hover:shadow-xl"
                  />
                  <h5 class="font-sans text-base font-bold text-black">
                    {{ group.name }}
                  </h5>
                  <p
                    v-if="group.conventions_count"
                    class="font-sourcePro text-sm font-normal text-slate-600"
                  >
                    {{ group.conventions_count }} Cons Attended
                  </p>
                </Link>
              </template>
            </div>
          </template>
        </div>

        <div
          v-if="!meetupsFetched || meetups?.length"
          class="relative mx-auto flex w-full max-w-screen-xl flex-col px-0 sm:px-2"
        >
          <div class="relative flex w-full flex-col px-4 xl:px-0">
            <span
              class="text-center font-poppins text-sm font-bold uppercase text-slate-400"
            >
              {{ pageType }}
            </span>
            <h2
              class="mb-8 flex w-full select-none flex-col gap-2 text-center font-poppins text-xl font-bold sm:text-2xl lg:text-3xl"
            >
              Meetups

              <a
                v-if="featureFlags.LinkToBrowsePage"
                :style="{
                  color: selectedEvent?.palette?.primary
                    ? selectedEvent.palette.primary
                    : '#000000',
                }"
                class="right-4 font-poppins text-base font-bold md:absolute xl:right-0"
                href=""
              >
                See all
              </a>

              <Link
                :style="{
                  color: selectedEvent?.palette?.primary
                    ? selectedEvent.palette.primary
                    : '#000000',
                }"
                class="left-4 mx-auto flex w-fit items-center gap-2 font-poppins text-base font-bold md:absolute xl:left-0"
                href="/meetups/create"
              >
                <font-awesome-icon
                  class="h-5 w-5"
                  icon="plus"
                />
                Create Meetup
              </Link>
            </h2>
          </div>

          <template v-if="findPreference">
            <div
              class="flex flex h-[210px] w-full flex-col items-center justify-center rounded-lg border border-dashed border-slate-300 p-6"
            >
              <h5
                class="mb-2 text-center font-poppins text-lg font-bold text-blue-muted"
              >
                Missing data
              </h5>
              <p
                class="mb-2 text-center font-poppins text-base font-normal text-blue-muted"
              >
                Please fill out your preferences to see more personalized
                special guests.
              </p>
              <button class="flex items-center gap-2 py-2 text-blue-primary">
                <font-awesome-icon
                  class="h-4 w-4 shrink-0"
                  icon="plus"
                />
                Add preferences
              </button>
            </div>
          </template>

          <div
            v-if="meetups?.length || !meetupsFetched"
            class="flex flex-col items-center gap-8 px-4 sm:px-0 md:items-start"
          >
            <group-activity-card
              v-for="meetup in meetups"
              :key="meetup.id"
              :activity="meetup"
              :loading="!meetupsFetched"
            />
          </div>
          <template v-else>
            <div class="mx-auto mt-8 flex w-fit flex-col justify-center gap-4">
              <span class="text-center font-poppins text-xl">
                No meetups yet.
                <br />
                Maybe it’s time to create one?
              </span>

              <Link
                :style="{
                  backgroundColor: selectedEvent?.palette?.primary
                    ? selectedEvent.palette.primary
                    : '#000000',
                }"
                class="mx-auto flex w-fit items-center gap-2 rounded-full px-10 py-3 text-center font-poppins text-base font-bold uppercase text-text-white hover:opacity-80"
                href="/meetups/create"
              >
                Create Meetup
              </Link>
            </div>
          </template>
        </div>
      </section>
    </template>
  </event-browse-layout>
</template>

<script lang="ts" setup>
import HoverCard from '@/components/ui/HoverCard.vue'

import { computed, onMounted, ref } from 'vue'

import MeetHero from './MeetHero.vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ImageComponent from '@/components/ui/ImageComponent.vue'
import GroupActivityCard from '@/components/ui/GroupActivityCard.vue'

import { Link } from '@inertiajs/vue3'
import SlideUpPopup from '../../../components/deprecated/SlideUpPopup.vue'
import axios from 'axios'
import SpecialGuestCard from '@/shared/components/event/sections/elements/SpecialGuestCard.vue'
import { useQuery } from '@tanstack/vue-query'
import useConventionList from '@/utils/composables/conventionList'
import { TODO } from '@/types/todo'
import UserGroup from '@/models/UserGroup'
import featureFlags from '@/utils/composables/flags'
import ConventionEvent from '@/models/ConventionEvent'

const meetupItem = {
  title: 'DC Comics Aficionados',
  description:
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected.',
  date: 'Sat, Mar 30',
  type: 'Meetup',
  time: '11:00a',
  room: 'Room 31B',
  location: 'SD Convention Center',
  logo: { path: 'https://picsum.photos/300/300' },
  image: { path: 'https://picsum.photos/600/300' },
}

const groupItem = {
  title: 'DC Comics Aficionados',
  description:
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected.',

  since: 'Since August 24, 2015',
  members_count: 254,
  image: { path: 'https://picsum.photos/600/300' },
}

const showItem = {
  title: 'House of the Dragon: season 2 ',
  description:
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected.',
  date: 'Sat, Mar 30',
  time: '11:00a',
  room: 'Room 31B',
  location: 'SD Convention Center',
  image: { path: 'https://picsum.photos/600/300' },
}

const gameItem = {
  type: 'Board Game',
  title: 'Rebirth',
  description:
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by. ',
  date: 'Sat, Mar 30',
  time: '11:00a',
  room: 'Room 31B',
  location: 'SD Convention Center',
  image: { path: 'https://picsum.photos/600/300' },
}

const findPreference = ref(false)
const pageType = ref('Featured')

const {
  selected: selectedEvent,
  onLoaded,
  fetch: fetchFullEvent,
} = useConventionList()

const { data: groups, isFetched: groupsFetched } = useQuery({
  queryKey: ['events', selectedEvent.value?.id, 'groups'],
  queryFn: () =>
    axios.get(`/api/conventions/${selectedEvent.value?.id}/groups`),
  select: (response) => response.data,
})

const { data: guests, isFetched: guestsFetched } = ConventionEvent.queryGuests(
  selectedEvent,
  {
    profession: 'featured',
  }
)

const { data: meetups, isFetched: meetupsFetched } = ConventionEvent.queryActivities(
  selectedEvent,
  {
    type: 'meetup',
    with: ['banner'],
  }
)

onMounted(() => {
  onLoaded().then(() => {
    if (!selectedEvent.value?.id) return
    fetchFullEvent(selectedEvent.value.id)
  })
})

const selectedGuest = ref(null)
</script>

<style scoped></style>
