<template>
  <div>
    <modal v-model:open="openFilterPopup">
      <modal-buttons-layout
        primary-button="Apply"
        secondary-button="Cancel"
        title=""
        @save="
          () => {
            applyFilters()
            openFilterPopup = false
          }
        "
        @cancel="openFilterPopup = false">
        <div class="flex flex-col gap-5 p-6 pb-32">
          <template v-for="filter in Object.keys(otherCategories)">
          <div v-if="categories[filter].options.length" :key="filter">
            <div
              v-if="
                categories[filter].options.length > 0 &&
                categories[filter].type === 'checkbox'
              "
              class="flex flex-col gap-2">
              <label class="text-sm">
                {{ categories[filter].title ?? filter }}
              </label>
              <div class="flex flex-col gap-2">
                <div
                  v-for="option in categories[filter].options"
                  :key="option.value"
                  class="flex flex-row items-center gap-2">
                  <input
                    type="checkbox"
                    class="form-checkbox"
                    :checked="selectedDuplicate[filter]?.includes(option.value)"
                    @change="select(filter, option)" />
                  <label class="cursor-pointer" @click="select(filter, option)">
                    {{ option.label }}
                  </label>
                </div>
              </div>
            </div>
            <input-layout
              layout="col"
              variant="gray"
              :label="otherCategories[filter].title ?? filter">
              <form-field-select-item
                disable-button
                :placeholder="
                  'Search ' +
                  (otherCategories[filter].title ?? filter)?.toLowerCase()
                "
                :selected="
                  selectedDuplicate[filter]?.map((s) =>
                    categories[filter].options.find((o) => o.value === s)
                  ) ?? []
                "
                key-by="value"
                search-by="label"
                :suggestions="categories[filter].options"
                @select="select(filter, $event)" />
            </input-layout>
            <!--            <search-with-results-->
            <!--              v-else-if="categories[filter].options.length > 0"-->
            <!--              placeholder="Search"-->
            <!--              :label="otherCategories[filter].title ?? filter"-->
            <!--              :close-on-select="false"-->
            <!--              :show-selected="false"-->
            <!--              show-selected-labels-->
            <!--              :selected="-->
            <!--                selectedDuplicate[filter]?.map((s) =>-->
            <!--                  categories[filter].options.find((o) => o.value === s)-->
            <!--                )-->
            <!--              "-->
            <!--              :results="getFilteredOptions(filter)"-->
            <!--              :model-value="queries[filter] ?? ''"-->
            <!--              @update:model-value="queries[filter] = $event"-->
            <!--              @select="select(filter, $event)">-->
            <!--              <template #item="{ item }">-->
            <!--                <div-->
            <!--                  class="flex cursor-pointer items-center gap-2"-->
            <!--                  :class="{-->
            <!--                    'text-blue-primary': selectedDuplicate[filter]?.includes(-->
            <!--                      item.value-->
            <!--                    ),-->
            <!--                  }">-->
            <!--                  &lt;!&ndash; check icon &ndash;&gt;-->
            <!--                  <div class="rounded border border-slate-300 p-1">-->
            <!--                    <svg-->
            <!--                      :class="{-->
            <!--                        'opacity-0': !selectedDuplicate[filter]?.includes(-->
            <!--                          item.value-->
            <!--                        ),-->
            <!--                      }"-->
            <!--                      class="h-6 w-6 cursor-pointer text-blue-primary"-->
            <!--                      fill="none"-->
            <!--                      stroke="currentColor"-->
            <!--                      viewBox="0 0 24 24"-->
            <!--                      xmlns="http://www.w3.org/2000/svg">-->
            <!--                      <path-->
            <!--                        stroke-linecap="round"-->
            <!--                        stroke-linejoin="round"-->
            <!--                        stroke-width="2"-->
            <!--                        d="M5 13l4 4L19 7" />-->
            <!--                    </svg>-->
            <!--                  </div>-->
            <!--                  {{ item.label }}-->
            <!--                </div>-->
            <!--              </template>-->
            <!--            </search-with-results>-->
          </div>
          </template>
        </div>
      </modal-buttons-layout>
    </modal>

    <div :class="getContainerClassList">
      <template v-if="main">
        <template v-if="loading">
          <div
            class="flex flex-row flex-nowrap justify-center gap-8 overflow-hidden text-transparent">
            <div
              class="skeleton block w-52 rounded-lg py-2 md:w-32 md:p-0"></div>
            <div class="skeleton hidden w-32 rounded-lg md:block"></div>
            <div class="skeleton hidden w-32 rounded-lg md:block"></div>
          </div>
        </template>
        <template v-else>
          <dropdown
            class="block md:hidden"
            :options="mainCategory.options.map((o) => o.label)"
            @selected="
              () => {
                select(main, mainCategory.options[$event])
                applyFilters()
              }
            " />
          <div
            class="hidden flex-row flex-nowrap justify-start gap-6 overflow-x-auto rounded-lg px-4 py-2 md:flex">
            <div
              v-for="(category, categoryIndex) in mainCategory.options"
              :key="categoryIndex"
              class="z-10 col-span-2 select-none whitespace-nowrap text-center text-base font-bold md:text-lg"
              :class="getCategoryClassList(isSelected(main, category.value))"
              @click="
                () => {
                  select(main, category.value)
                  applyFilters()
                }
              ">
              {{ category.label }}
            </div>
          </div>
        </template>
      </template>

      <!-- display filter buttons only when page is loaded -->
      <div v-if="!loading" class="flex items-center gap-6">
        <template v-if="searchEnabled">
          <button @click="emit('search')">
            <font-awesome-icon icon="search" class="text-slate-700" />
          </button>
        </template>
        <div
          v-if="Object.keys(otherCategories).length"
          class="flex cursor-pointer select-none items-center justify-center rounded px-2 py-2"
          :class="{
            'bg-slate-700 text-white': areOtherFiltersSet,
            'text-slate-700 ': !areOtherFiltersSet,
          }"
          @click="
            () => {
              duplicateFilters()
              openFilterPopup = true
            }
          ">
          <font-awesome-icon icon="filter" class="h-5 w-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from '../input/Dropdown.vue'
import { computed, onMounted, PropType, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Filter } from '../../utils/filters'
import Modal from '../modal/Modal.vue'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import InputLayout from '@/components/layout/InputLayout.vue'

const emit = defineEmits(['update:selected', 'search'])

const props = defineProps({
  variant: {
    type: String,
    default: 'simple', // simple, button, full, left
  },
  categories: {
    type: Object as PropType<Record<string, Filter>>,
    required: true,
  },
  main: {
    type: String,
    required: true,
  },
  selected: {
    type: Object as PropType<Record<string, any>>,
    default: () => {
      return {}
    },
  },
  searchEnabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => props.selected,
  () => {
    console.log('selected changed')
    duplicateFilters()
  }
)

onMounted(() => {
  duplicateFilters()
})

const getContainerClassList = computed(() => {
  return {
    'w-full flex flex-row items-center gap-4 justify-between':
      props.variant === 'simple',
    '': props.variant === 'button',
    'w-full bg-gray-100 mx-auto py-2.5 px-4 flex flex-row justify-between items-center gap-4':
      props.variant === 'full',
    'w-full items-center justify-end flex flex-row-reverse gap-6':
      props.variant === 'left',
  }
})

const getCategoryClassList = (selected) => {
  if (props.variant === 'simple') {
    return {
      'font-bold text-blue-primary select-none': selected,
      'font-normal text-slate-500 hover:text-slate-900 select-none cursor-pointer':
        !selected,
    }
  }
  if (props.variant === 'full') {
    return {
      'bg-white p-2.5 text-slate-400 select-none cursor-pointer hover:bg-slate-100':
        !selected,
      'bg-slate-500 p-2.5 text-white select-none': selected,
    }
  }

  return {
    'font-bold text-blue-primary select-none': selected,
    'text-slate-500 hover:text-slate-900 select-none cursor-pointer': !selected,
  }
}

const otherCategories = computed<Record<string, Filter>>(() => {
  return Object.keys(props.categories)
    .filter((category) => category !== props.main)
    .reduce(
      (obj, key) => {
        obj[key] = props.categories[key]
        return obj
      },
      {} as Record<string, Filter>
    )
})

const openFilterPopup = ref<boolean>(false)
const activeFilterTab = ref<string>(Object.keys(otherCategories.value)[0])
const queries = ref<Record<string, string>>({})

const activeTabCategory = computed<Filter>(() => {
  return props.categories[activeFilterTab.value]
})
const areOtherFiltersSet = computed<boolean>(() => {
  return Object.keys(otherCategories.value).some(
    (category) => selectedDuplicate.value[category]?.length
  )
})

const selectedDuplicate = ref<Record<string, any[]>>({})

function duplicateFilters() {
  selectedDuplicate.value = JSON.parse(JSON.stringify(props.selected))
}

function applyFilters() {
  emit('update:selected', selectedDuplicate.value)
}

function revertFilters() {
  selectedDuplicate.value = JSON.parse(JSON.stringify(props.selected))
}

function resetFilters() {
  emit('update:selected', {})
  selectedDuplicate.value = {}
}

const mainCategory = computed<Filter>(() => {
  return props.categories[props.main]
})

function isSelected(filter: string, value: string) {
  return selectedDuplicate.value[filter]?.includes(value)
}

function select(filter: string, value: string | any) {
  if (
    (typeof value !== 'string' && typeof value !== 'number')
  ) {
    value = value.value
  }

  if (
    selectedDuplicate.value[filter] == null ||
    selectedDuplicate.value[filter] == undefined
  ) {
    selectedDuplicate.value[filter] = []
  }

  if (!props.categories[filter].allow_multiple) {
    if (selectedDuplicate.value[filter].includes(value)) {
      if (filter !== props.main) {
        selectedDuplicate.value[filter] = null
      }
    } else {
      selectedDuplicate.value[filter] = [value]
    }
  } else {
    if (selectedDuplicate.value[filter].includes(value)) {
      selectedDuplicate.value[filter] = selectedDuplicate.value[filter].filter(
        (item) => item !== value
      )
    } else {
      selectedDuplicate.value[filter].push(value)
    }
  }
}

function getFilteredOptions(filter: string) {
  return props.categories[filter].options.filter((option) => {
    return option.label
      ?.toLowerCase()
      .includes(queries.value[filter]?.toLowerCase() ?? '')
  })
}
</script>

<style scoped></style>