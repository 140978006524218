<template>
  <div class="relative flex w-full justify-center bg-slate-100">
    <div class="flex w-full max-w-5xl flex-col items-center shadow-xl">
      <restaurant-hero-section
        v-if="pageType === 'Restaurant'"
        v-model:edit="edit"
        :enable-edit="enableEdit"
        :section="getSectionByType('restaurant-hero')"
        :theme="showcasePage.theme"
      />
      <showcase-hero-section
        v-else
        v-model:edit="edit"
        :enable-edit="enableEdit"
        :section="getSectionByType('hero')"
        :theme="showcasePage.theme"
      />
      <products-section
        v-if="getSectionByType('products').products.length || edit"
        :event-slug="selectedEvent?.slug"
        :page-type="pageType"
        :section="getSectionByType('products')"
        :theme="showcasePage.theme"
        :vendor="showcasePage.showcaseable"
      />

      <activities-section
        v-if="getSectionByType('activities').events.length || edit"
        :section="getSectionByType('activities')"
        :theme="showcasePage.theme"
      />

      <location-venues-section
        v-if="getSectionByType('locations')"
        :section="getSectionByType('locations')"
        :theme="showcasePage.theme"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ShowcaseHeroSection from '@/components/ui/showcase/ShowcaseHeroSection.vue'
import ProductsSection from '@/components/ui/showcase/ProductsSection.vue'
import ActivitiesSection from '@/components/ui/showcase/ActivitiesSection.vue'
import LocationVenuesSection from '@/components/ui/showcase/LocationVenuesSection.vue'
import useConventionList from '@/utils/composables/conventionList'
import ShowcasePage from '@/models/ShowcasePage'
import Vendor from '@/models/Vendor'
import { provide, ref, watch } from 'vue'
import axios from 'axios'
import { buildFormData } from '@/utils/form'
import RestaurantHeroSection from '@/components/ui/showcase/RestaurantHeroSection.vue'

const props = defineProps({
  showcasePage: {
    type: [ShowcasePage<Vendor>],
    required: true,
  },
  includeDefaultSections: {
    type: Boolean,
    default: false,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
  pageType: {
    type: String,
    default: 'Showcase',
  },
})

const { selected: selectedEvent } = useConventionList()

const edit = ref(false)
watch(
  () => edit.value,
  (value) => {
    if (!value) {
      const data = buildFormData({ sections: props.showcasePage.sections })
      axios.post(
        `/api/vendors/${props.showcasePage.showcaseable.id}/showcase/sections?_method=PUT`,
        data
      )
    }
  }
)
provide('showcase-edit-mode', edit)

function getSectionByType(type) {
  return props.showcasePage.sections[type] ?? null
}
</script>

<style scoped></style>
