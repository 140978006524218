<template>
  <event-browse-layout>
    <template
      v-if="selectedEvent"
      #browse-page
    >
      <event-hero-section
        v-if="selectedEvent"
        :event="selectedEvent"
        class="mx-auto max-w-[1200px]"
      />

      <section
        v-if="guests?.length"
        class="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-y-8 bg-text-white px-4 py-20 md:px-8"
      >
        <h2
          class="text-center font-heading text-4xl text-dark-grey sm:text-[40px]"
        >
          Special Guests
        </h2>
        <div
          class="flex w-full flex-col flex-nowrap justify-start gap-8 py-4 md:flex-row md:overflow-x-auto"
        >
          <background-image-component
            :image="selectedEvent.banner"
            class="sticky left-0 z-floating flex shrink-0 flex-col rounded-lg bg-black/50 bg-cover bg-center bg-no-repeat p-14 px-4 py-8 bg-blend-darken md:w-1/3"
          >
            <div class="mb-0 mt-auto">
              <h5 class="rounded-lg text-center text-[32px] text-text-white">
                Explore {{ selectedEvent.name }} Special Guests
              </h5>
              <Link
                :href="`/events/${selectedEvent.slug}/meet`"
                class="mx-auto mt-4 flex w-fit cursor-pointer select-none items-center gap-2 text-text-white"
              >
                See all guests
                <chevron-right />
              </Link>
            </div>
          </background-image-component>
          <div class="flex gap-8 overflow-x-auto md:overflow-visible">
            <special-guest-card
              v-for="guest in guests"
              :key="guest.id"
              :event="selectedEvent"
              :guest="guest"
              :loading="!guestsFetched"
              :palette="selectedEvent.palette"
              background-image
            />
          </div>
        </div>
      </section>

      <div
        v-if="selectedEvent?.featured?.length !== 0"
        class="mx-auto flex w-full max-w-[1200px] flex-col gap-8 overflow-hidden bg-white px-4 py-20 md:px-8"
      >
        <h2
          class="text-center font-heading text-4xl text-dark-grey sm:text-[40px]"
        >
          Schedule Highlights
        </h2>
        <conventions-section
          :event="selectedEvent"
          :palette="selectedEvent.palette"
          class="w-full bg-transparent !p-0"
          convention-home
          wrapper-class="!p-0 !shadow-none !rounded-none"
        />
      </div>

      <event-news-updates-section
        v-if="featureFlags.HomeNews"
        :data="{}"
      />

      <raffle-prizes-section
        v-if="featureFlags.HomeRaffles"
        :palette="selectedEvent?.palette"
        image-path="https://picsum.photos/900/600"
      />

      <event-tickets-section
        v-if="featureFlags.HomeTickets"
        :palette="selectedEvent?.palette"
      />

      <div
        v-if="featureFlags.HomeKeyDates"
        class="mx-auto flex w-full max-w-[1200px] flex-col gap-8 bg-text-white px-4 py-20 md:px-8"
      >
        <h2
          class="text-center font-heading text-4xl text-dark-grey sm:text-[40px]"
        >
          Key Dates
        </h2>
        <key-dates-section
          :key-dates="{}"
          :palette="selectedEvent?.palette"
          image="https://picsum.photos/900/600"
        />
      </div>

      <location-venues-section
        v-if="featureFlags.HomeLocations"
        :section="{}"
        :theme="{}"
        class="mx-auto max-w-[1200px] !px-8 !py-20"
      />

      <section
        class="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-y-8 bg-text-white px-4 py-20 md:px-8"
      >
        <h2
          class="text-center font-heading text-4xl text-dark-grey sm:text-[40px]"
        >
          {{ selectedEvent.name }} Gallery
        </h2>

        <div class="flex max-w-full gap-4 overflow-scroll md:flex-wrap">
          <background-image-component
            v-if="selectedEvent?.banner !== null"
            :src="selectedEvent?.banner?.path"
            class="object-fit flex h-[388px] w-[514px] shrink-0 flex-col items-center justify-center rounded-lg"
          >
            <image-component
              v-if="selectedEvent?.logo"
              :image="selectedEvent.logo"
              class="object-fit w-[300px]"
            />
          </background-image-component>

          <template v-if="!posts?.length">
            <div
              class="h-full shrink-0 cursor-pointer rounded-lg bg-gray-100 p-3 hover:bg-gray-200"
            >
              <button
                :style="{
                  borderColor: selectedEvent?.palette?.primary
                    ? selectedEvent?.palette.primary
                    : '#797FFF',
                }"
                class="flex h-[376px] w-[502px] flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed"
                @click="createThreadPost"
              >
                <div>
                  <svg
                    :style="{
                      fill: selectedEvent?.palette?.primary
                        ? selectedEvent?.palette.primary
                        : '#1613BD',
                    }"
                    aria-hidden="true"
                    class="svg-inline--fa fa-arrow-up-from-bracket"
                    data-icon="arrow-up-from-bracket"
                    data-prefix="fas"
                    focusable="false"
                    role="img"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class=""
                      d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <p
                  :style="{
                    color: selectedEvent?.palette?.primary
                      ? selectedEvent?.palette.primary
                      : '#797FFF',
                  }"
                >
                  <strong>Capture & Connect 🌟</strong>
                  <br />
                  Share your favorite shots!
                </p>
              </button>
            </div>
          </template>
          <template v-else>
            <background-image-component
              v-for="post in posts"
              :key="post.id"
              :image="post.image"
              class="relative h-[388px] w-[249px] shrink-0 overflow-hidden rounded-lg !object-cover"
              @click="
                modalHandler.openType('thread', {
                  props: {
                    enableActions: true,
                    post: toRaw(post),
                  },
                  events: {
                    onDelete() {
                      refetchPosts()
                    },
                  },
                })
              "
            >
              <div
                v-if="post.author"
                class="absolute inset-0 flex items-end bg-gradient-to-b from-transparent from-60% to-black p-4 text-white opacity-0 duration-100 hover:opacity-100"
              >
                <div class="flex items-center gap-2">
                  <avatar-image
                    :image="post.author.avatar"
                    class="h-8 w-8"
                  />
                  <div>
                    <h2 class="line-clamp-1 text-sm">{{ post.title }}</h2>
                    <small class="line-clamp-1 text-xs">
                      {{ post.author.name }}
                    </small>
                  </div>
                </div>
              </div>
            </background-image-component>
          </template>
        </div>

        <div class="mx-auto text-center text-2xl text-black">
          Have images that you’d like to share with us?
          <button
            :style="{
              color: selectedEvent?.palette?.primary
                ? selectedEvent?.palette.primary
                : '#797FFF',
            }"
            class="mx-auto block text-center font-bold"
            @click="createThreadPost"
          >
            Upload here
          </button>
        </div>
      </section>

      <section
        v-if="featureFlags.Sponsors"
        class="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-y-8 bg-text-white px-4 pb-40 pt-20 md:px-8"
      >
        <h2
          class="text-center font-heading text-4xl text-dark-grey sm:text-4xl sm:text-[40px]"
        >
          Sponsors
        </h2>
        <div
          class="flex max-w-full items-center items-center justify-center gap-5 overflow-scroll sm:max-w-[900px] sm:flex-wrap sm:gap-20"
        >
          <img
            v-for="(logo, index) in []"
            :key="index"
            :src="logo"
            alt="sponsor logo"
            class="h-[90px] w-auto object-contain"
          />
        </div>
      </section>

      <background-image-component
        v-if="featureFlags.Volunteers"
        :image="selectedEvent.banner"
        class="relative z-[21] flex w-full flex-col items-start bg-cover bg-center bg-no-repeat px-4 py-20 md:px-8"
      >
        <div
          class="absolute inset-0 z-10 h-full w-full"
          style="
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.8) 100%
            );
          "
        />

        <div class="relative z-20 mx-auto flex flex-col gap-4 text-center">
          <h2 class="font-heading text-4xl text-white sm:text-[40px]">
            Become A Volunteer
          </h2>
          <p class="font-heading text-lg text-white sm:max-w-[600px]">
            A quick brown fox jumps over the lazy dog.
          </p>
        </div>
        <button
          :style="{
            backgroundColor: selectedEvent?.palette?.primary
              ? selectedEvent?.palette.primary
              : '#1613BD',
          }"
          class="relative z-20 mx-auto mt-5 select-none rounded-lg px-10 py-3 font-heading text-base text-white"
        >
          Become a Volunteer
        </button>
      </background-image-component>
    </template>
  </event-browse-layout>
</template>

<script lang="ts" setup>
import { onMounted, toRaw } from 'vue'
import useConventionList from '@/utils/composables/conventionList'
import ConventionsSection from '@/components/ui/ConventionsSection.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'
import EventHeroSection from '@/shared/components/event/sections/EventHeroSection.vue'
import EventNewsUpdatesSection from '@/shared/components/event/sections/EventNewsUpdatesSection.vue'
import RafflePrizesSection from '@/shared/components/event/sections/RafflePrizesSection.vue'
import EventTicketsSection from '@/shared/components/event/sections/EventTicketsSection.vue'
import KeyDatesSection from '@/shared/components/event/sections/KeyDatesSection.vue'
import LocationVenuesSection from '@/components/ui/showcase/LocationVenuesSection.vue'
import SpecialGuestCard from '@/shared/components/event/sections/elements/SpecialGuestCard.vue'
import { useModal } from '@/utils/modal'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import ChevronRight from '@/icons/ChevronRight.vue'
import { Link } from '@inertiajs/vue3'
import ConventionEvent from '@/models/ConventionEvent'
import featureFlags from '@/utils/composables/flags'

const { selected: selectedEvent, onLoaded, fetch } = useConventionList()
const modalHandler = useModal()

const { data: guests, isFetched: guestsFetched } = ConventionEvent.queryGuests(
  selectedEvent,
  {
    profession: 'featured',
  }
)

const {
  data: posts,
  isFetched: postsFetched,
  refetch: refetchPosts,
} = ConventionEvent.queryPosts(selectedEvent, {
  type: 'images',
})

onMounted(() => {
  onLoaded().then(() => {
    if (selectedEvent.value?.id) fetch(selectedEvent.value?.id)
  })
})

function createThreadPost() {
  modalHandler.openType('threadPost', {
    props: {
      conventionId: selectedEvent.value?.id,
    },
    events: {
      onCreate() {
        refetchPosts()
      }
    }
  })
}
</script>
