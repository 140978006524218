<template>
  <event-browse-layout :active="isMenuItem ? 'eat':'shop'">
    <review-modal
      :is-open="reviewPopupOpen"
      :vendor-product-id="product.id"
      @close="reviewPopupOpen = false"
      @submit="submitReview($event)"
    />
    <div class="bg-[#EAEDF5] pb-10">
      <!-- Full width banner image for restaurant -->
      <div class="flex w-full justify-center">
        <background-image-component
          :alt="vendor.name"
          :src="vendor.banner?.path ?? vendor.icon?.path"
          class="absolute left-0 right-0 top-0 z-0 hidden h-[400px] bg-cover bg-center bg-no-repeat opacity-90 md:block"
        />
      </div>

      <div
        :class="
          props.isMenuItem ? 'gap-y-4 md:gap-y-20' : 'gap-y-10 md:gap-y-14'
        "
        class="relative z-[2] mx-auto flex max-w-[1087px] flex-col rounded-lg bg-white p-0 font-heading md:mt-[160px] md:p-[40px]"
      >
        <section class="flex flex-col justify-between md:flex-row">
          <background-image-component
            v-if="props.isMenuItem"
            :alt="product.name"
            :src="product.images[0]?.path"
            class="z-10 mb-4 mr-0 h-[200px] w-full max-w-full bg-slate-200 bg-cover bg-center bg-no-repeat object-cover shadow-lg md:mb-0 md:mr-4 md:h-[300px] md:max-w-[300px] md:rounded-lg"
          />

          <div
            v-else
            class="mb-2 flex flex-col gap-1 md:mb-0 md:mr-5"
          >
            <div
              class="mx-auto max-w-[344px] overflow-x-scroll"
              @scroll="handleScroll"
            >
              <div class="relative flex max-w-[344px]">
                <background-image-component
                  v-for="image in product.images"
                  :key="image.id"
                  :alt="product.name"
                  :src="image.path"
                  class="z-10 mb-4 mr-0 h-[316px] min-w-[344px] max-w-[344px] rounded-lg bg-slate-200 bg-cover bg-center bg-no-repeat object-cover shadow-lg md:mb-0 md:mr-4"
                />
              </div>
              <!-- Pagination circles -->
            </div>
            <div
              v-if="product.images.length > 1"
              class="mt-2 flex justify-center gap-2"
            >
              <div
                v-for="(image, index) in product.images"
                :key="image.id"
                :class="[
                  'h-3 w-3 cursor-pointer rounded-full',
                  {
                    'bg-[#818FFF]': selectedImageIndex === index,
                    'bg-gray-400': selectedImageIndex !== index,
                  },
                ]"
                @click="scrollToImage(index)"
              />
            </div>
          </div>

          <div
            class="mr-0 flex w-[100%] max-w-[615px] flex-col px-4 md:ml-auto md:px-0"
          >
            <div class="flex items-center">
              <span
                class="ml-0 mr-auto font-sans text-sm font-semibold text-blue-neon"
              >
                <template v-if="props.isMenuItem">Dish</template>
                <template v-else>Product</template>
              </span>
              <!-- Availability -->
              <span
                v-if="isMenuItem"
                class="ml-auto mr-0 min-w-[120px] text-sm font-semibold text-[#878D97] text-slate-500"
              >
                {{ isRestaurantOpen ? 'Open until 9pm' : 'Opens at 10am' }}
              </span>
            </div>
            <!-- Centered name of the menu item -->
            <h2
              class="mb-0 mt-1 text-left font-heading text-2xl font-semibold md:text-[32px]"
            >
              {{ product.name }}
            </h2>

            <span
              v-if="!props.isMenuItem"
              class="ml-0 mr-auto font-sans text-sm font-semibold text-blue-neon"
            >
              Visit {{ vendor.name }}
            </span>

            <!-- Description -->
            <p
              class="mb-5 mt-4 text-left font-sans text-base text-paragraph opacity-90 sm:mt-6"
            >
              {{ product.description }}
            </p>

            <!-- Price, Distance -->
            <div
              :class="props.isMenuItem ? 'text-xl' : 'text-3xl'"
              class="flex gap-16 font-heading font-semibold text-dark-grey"
            >
              <span>${{ product.price }}</span>
              <span v-if="state.latitude && state.longitude">
                {{
                  calculateDistance(
                    state.latitude,
                    state.longitude,
                    product.latitude,
                    product.longitude
                  )
                }}
              </span>
            </div>

            <!-- Ratings for: Value, Taste, Service -->
            <div
              v-if="showReviewRatings"
              class="mt-5 flex gap-4 font-heading text-sm text-blue-neon"
            >
              <h4
                class="w-[100%] max-w-[121px] rounded-lg bg-[#F4F6FA] px-4 py-2 font-heading"
              >
                Value
                <br />
                <span class="text-2xl text-dark-grey">
                  {{ getReviewRatings.value_rating }}
                </span>
              </h4>
              <h4
                class="w-[100%] max-w-[121px] rounded-lg bg-[#F4F6FA] px-4 py-2 font-heading"
              >
                Taste
                <br />
                <span class="text-2xl text-dark-grey">
                  {{ getReviewRatings.taste_rating }}
                </span>
              </h4>
              <h4
                class="w-[100%] max-w-[121px] rounded-lg bg-[#F4F6FA] px-4 py-2 font-heading"
              >
                Service
                <br />
                <span class="text-2xl text-dark-grey">
                  {{ getReviewRatings.service_rating }}
                </span>
              </h4>
            </div>

            <!-- CTA to go there -->
            <div
              v-if="isMenuItem"
              class="mt-5 flex flex-row font-heading text-base"
            >
              <button
                v-if="false"
                class="block rounded rounded-full bg-ultra-light-blue px-8 py-2 font-semibold text-blue-primary"
                @click="reviewPopupOpen = true"
              >
                Review dish
              </button>
              <button
                class="block flex items-center rounded px-8 py-2 font-semibold text-blue-primary"
                @click="openMap(product.latitude, product.longitude)"
              >
                View location
                <div>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 17 16"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 3.33203L11.1667 7.9987L6.5 12.6654"
                      stroke="url(#paint0_linear_1823_3063)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1823_3063"
                        gradientUnits="userSpaceOnUse"
                        x1="8.83333"
                        x2="8.83333"
                        y1="3.33203"
                        y2="12.6654"
                      >
                        <stop
                          offset="0.21875"
                          stop-color="#1F1BDB"
                        />
                        <stop
                          offset="1"
                          stop-color="#100DA8"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </section>

        <hr
          v-if="!props.isMenuItem"
          class="h-1 w-full"
        />

        <!-- Full width section with background color, but list other products in the restaurant -->
        <section class="flex flex-col justify-center">
          <div class="mb-[40px] text-center">
            <h2 class="mb-0 text-center font-heading text-[28px] font-semibold">
              Other
              <template v-if="isMenuItem">Dishes</template>
              <template v-else>Products</template>
            </h2>
            <div class="font-sans text-sm font-semibold text-slate-700">
              <template v-if="isMenuItem">from</template>
              <template v-else>at</template>
              {{ vendor.name }}
            </div>
          </div>
          <div
            v-if="props.isMenuItem"
            class="mx-auto grid w-full gap-1 gap-6 px-4 sm:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4"
          >
            <item-card
              v-for="(menuItem, index) in recommendedProducts"
              :key="index"
              :event-slug="currentConvention.slug"
              :item="menuItem"
              :state="state"
              class="grid-item mx-auto"
            />
          </div>
          <div
            v-else
            class="mx-auto grid w-full max-w-screen-2xl grid-cols-1 gap-x-6 gap-y-12 px-2 sm:grid-cols-2 md:px-4 lg:grid-cols-3 lg:px-6 xl:px-8 xxl:grid-cols-3"
          >
            <product-item-card
              v-for="(menuItem, id) in recommendedProducts"
              :key="id"
              :event-slug="currentConvention.slug"
              info-in-column
              :product-item="menuItem"
              :state="state"
              card-style="h-full flex w-full cursor-pointer select-none mx-auto rounded-lg"
              img-style="relative flex aspect-square w-full items-end justify-start overflow-hidden rounded-lg bg-slate-200 bg-cover bg-center hover:shadow-lg h-[200px]"
              style="
                border: 1px solid #eaeaf5;
                box-shadow: 0px 2px 8px 0px #d3dbff;
              "
              wrap-style="relative flex h-full w-full flex-col md:w-full"
            />
          </div>
        </section>

        <hr
          v-if="!props.isMenuItem"
          class="h-1 w-full"
        />

        <section class="bg-white px-4 pb-0 md:px-0">
          <div
            class="mx-auto max-w-7xl rounded-lg border-[1px] border-solid border-[#EAEAF5] p-3 shadow-lg md:border-none md:px-2 md:py-0 md:shadow-none lg:px-8"
          >
            <div
              class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-6 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
            >
              <div class="lg:pr-4">
                <div
                  class="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10"
                >
                  <image-component
                    :alt="vendor.name"
                    :src="vendor.banner?.path"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
              </div>
              <div class="px-4 lg:px-0">
                <div
                  class="flex flex-col text-base leading-7 text-gray-700 lg:max-w-lg"
                >
                  <div class="flex items-baseline">
                    <p class="text-sm font-semibold leading-7 text-blue-neon">
                      {{ getTagsCaption() }}
                    </p>
                    <!-- Availability -->
                    <span
                      v-if="isMenuItem"
                      class="ml-auto mr-0 min-w-[120px] min-w-[96px] text-sm font-semibold text-[#878D97] text-slate-500"
                    >
                      {{
                        isRestaurantOpen ? 'Open until 9pm' : 'Opens at 10am'
                      }}
                    </span>
                  </div>
                  <Link
                    :href="`/events/${currentConvention.slug}/${
                      props.isMenuItem ? 'restaurants' : 'shops'
                    }/${vendor.slug}`"
                    class="mb-0 mt-2 font-heading text-xl font-semibold tracking-tight text-dark-grey sm:text-2xl"
                  >
                    {{ vendor.name }}
                  </Link>
                  <p class="mt-4 max-w-xl font-heading text-lg">
                    {{ vendor.description }}
                  </p>
                </div>
                <dl
                  v-if="props.isMenuItem"
                  class="mt-6 flex gap-4"
                >
                  <template v-for="(stat, statIdx) in stats">
                    <div
                      v-if="vendor.links.map((t) => t.type).includes(stat.type)"
                      :key="statIdx"
                      class="w-[100%] max-w-[190px] rounded-lg bg-[#F4F6FA] px-4 py-2"
                    >
                      <dt
                        class="text-sm font-semibold leading-6 text-blue-primary"
                      >
                        <a
                          :href="
                            vendor.links.find((t) => t.type === stat.type).url
                          "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {{ stat.label }}
                        </a>
                      </dt>
                      <div class="flex flex-col items-baseline sm:flex-row">
                        <dd
                          class="mr-1 text-xl font-bold leading-10 tracking-tight text-gray-900 md:text-2xl"
                        >
                          {{
                            vendor.ratings.find(
                              (r) =>
                                ((stat.type === 'yelp' &&
                                  r.name.startsWith('yelp_')) ||
                                  (stat.type === 'open-table' &&
                                    r.name.startsWith('ot_'))) &&
                                (r.name.endsWith('_review_stars') ||
                                  r.name.endsWith('_review_stat'))
                            )?.value ?? 'N/A'
                          }}
                        </dd>
                        <dt
                          class="text-sm font-semibold leading-6 text-slate-400"
                        >
                          ({{
                            vendor.ratings.find(
                              (r) =>
                                ((stat.type === 'yelp' &&
                                  r.name.startsWith('yelp_')) ||
                                  (stat.type === 'open-table' &&
                                    r.name.startsWith('ot_'))) &&
                                r.name.endsWith('_review_count')
                            )?.value
                          }}
                          reviews)
                        </dt>
                      </div>
                    </div>
                  </template>
                </dl>
                <div
                  v-if="props.isMenuItem"
                  class="mt-10 flex"
                >
                  <a
                    :href="`/events/${currentConvention.slug}/restaurants/${vendor.slug}`"
                    class="mx-auto flex items-center text-base font-semibold leading-7 text-blue-primary md:mx-0"
                  >
                    Learn more
                    <div>
                      <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 17 16"
                        width="17"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 3.33203L11.1667 7.9987L6.5 12.6654"
                          stroke="url(#paint0_linear_1823_3063)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1823_3063"
                            gradientUnits="userSpaceOnUse"
                            x1="8.83333"
                            x2="8.83333"
                            y1="3.33203"
                            y2="12.6654"
                          >
                            <stop
                              offset="0.21875"
                              stop-color="#1F1BDB"
                            />
                            <stop
                              offset="1"
                              stop-color="#100DA8"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </event-browse-layout>
</template>

<script setup>
import { computed, onBeforeMount, reactive, ref } from 'vue'
import ItemCard from './ItemCard.vue'
import BackgroundImageComponent from '../../../components/ui/BackgroundImageComponent.vue'
import ImageComponent from '../../../components/ui/ImageComponent.vue'
import ReviewModal from '../../../components/modal/ReviewModal.vue'
import { getUserLocation } from '../../../plugins/GetUserLocation'
import { calculateDistance } from '../../../plugins/CalculateDistance'
import { Link, usePage } from '@inertiajs/vue3'
import useConventionList from '@/utils/composables/conventionList'
import ProductItemCard from '@/views/www/Products/ProductItemCard.vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  recommendedProducts: {
    type: Array,
    required: true,
  },
  vendor: {
    type: Object,
    required: true,
  },
  isMenuItem: {
    type: Boolean,
    default: true,
  },
})

const state = reactive({
  latitude: ref(0),
  longitude: ref(0),
  loadingLocation: true,
  error: ref(''),
})

const page = usePage()
const { selected: currentConvention } = useConventionList()

const stats = [
  { label: 'Yelp', type: 'yelp', value: '4.8', reviewCount: '100' },
  {
    label: 'OpenTable',
    type: 'open-table',
    value: '4.5',
    reviewCount: '330',
  },
]
const reviewPopupOpen = ref(false)

function submitReview(review) {
  review.post('/menu-items/' + props.product.id + '/reviews', {
    preserveScroll: true,
    onSuccess: () => {
      reviewPopupOpen.value = false
    },
  })
}

// create getTagsCaption that joins vendor.tags[array].name with " - "
const getTagsCaption = () => {
  const tagNames = props.vendor.menu_item_tags.map((tag) => tag.name)
  return tagNames.join(' - ')
}

// Sample data, replace with your actual data
const restaurant = ref({
  name: 'Restaurant Name',
  banner: 'https://resizer.otstatic.com/v2/photos/wide-huge/3/32346346.jpg',
  image: 'https://resizer.otstatic.com/v2/photos/wide-huge/3/32346346.jpg',
  tags: ['Mexican', 'Southwestern'],
  yelpStars: 4,
  yelpReviews: 100,
  openTableStars: 4.5,
  openTableReviews: 200,
  description: 'This is a restaurant description.',
  dayTimes: 'Mon-Fri 9am-9pm',
  contactInfo: '(123) 456-7890',
  location: '123 Main St, City, Country',
})

const isRestaurantOpen = ref(true)

const showReviewRatings = computed(() => {
  if (
    props.product.review_rating_stats &&
    props.product.review_rating_stats.taste_rating &&
    props.product.review_rating_stats.service_rating &&
    props.product.review_rating_stats.value_rating
  ) {
    return true
  }
  return false
})

const getReviewRatings = computed(() => {
  return showReviewRatings.value ?? props.product.review_rating_stats
})

const ratings = ref({
  value: 4,
  taste: 4.5,
  service: 4.2,
})

onBeforeMount(async () => {
  state.loadingLocation = true
  try {
    const { coords } = await getUserLocation()
    state.latitude = coords.latitude
    state.longitude = coords.longitude
  } catch (error) {
    state.error = error.message
  } finally {
    state.loadingLocation = false
  }
})

const selectedImageIndex = ref(0)

const handleScroll = (event) => {
  const element = event.target
  const scrollPosition = element.scrollLeft
  const imageWidth = 344
  const newIndex = Math.floor(scrollPosition / imageWidth)
  selectedImageIndex.value = newIndex
}

const scrollToImage = (index) => {
  const imageWidth = 360
  const scrollPosition = index * imageWidth

  const scrollContainer = document.querySelector('.overflow-x-scroll')

  if (scrollContainer) {
    scrollContainer.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    })
  }

  selectedImageIndex.value = index
}
</script>

<style scoped></style>
