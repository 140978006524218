<template>
  <hover-card
    :background="item.banner"
    type="meetup"
  >
    <template #content="{ isHovered }">
      <image-component
        v-if="item?.logo?.path && !isHovered"
        :src="item.logo.path"
        class="absolute left-4 top-4 h-[70px] w-[70px] object-contain"
      />
      <span
        v-if="item?.type"
        class="z-[2] mb-1 text-center font-sourcePro text-base font-bold text-red"
      >
        {{ item.type?.name }}
      </span>
      <h4
        class="z-[2] text-center font-sourcePro text-xl font-bold text-text-white"
      >
        {{ item?.name }}
      </h4>
      <p
        v-if="isHovered && item?.description"
        class="z-[2] mt-2 line-clamp-3 h-full !max-h-[75px] min-h-fit max-w-full text-center font-sourcePro text-base font-normal text-text-white"
      >
        {{ item.description }}
      </p>

      <div
        v-if="item.starts_at"
        class="z-[2] flex items-center text-center font-sourcePro text-sm text-slate-300 *:flex *:items-center *:gap-1 [&:not(:last-child)]:*:after:mx-1 [&:not(:last-child)]:*:after:content-['•']"
      >
        <span>
          <calendar-icon-v2 class="h-4.5 w-4.5" />
          {{ DateTime.fromISO(item.starts_at).toFormat('ccc, LLL d') }}
        </span>
        <!--        <span v-if="item.attendee_count">-->
        <!--          <clock-icon class="h-4.5 w-4.5" />-->
        <!--          {{ item.attendee_count }}-->
        <!--          {{ pluralize(item.attendee_count, 'Attendee') }}-->
        <!--        </span>-->
        <span>
          <clock-icon class="h-[18px] w-[18px] shrink-0" />
          {{
            DateTime.fromISO(item.starts_at)
              .toFormat('hh:mma')
              .toLowerCase()
              .slice(0, -1)
          }}
        </span>
      </div>

      <div
        v-if="isHovered && item?.location"
        class="z-[2] mt-4 inline flex items-center gap-1 text-center font-sourcePro text-sm text-slate-300"
      >
        <pin-icon class="h-[18px] w-[18px] shrink-0" />
        <template v-if="item.location.type === 'venue'">
          {{ item.location.venue.name }}
        </template>
        <template v-else-if="item.location.type === 'venue_space'">
          {{ item.location.venue_space.name }} • {{ item.location.venue.name }}
        </template>
        <template v-else-if="item.location.type === 'venue_station'">
          {{ item.location.venue_station.name }} • {{ item.location.venue.name }}
        </template>
      </div>

      <div
        v-if="isHovered"
        class="z-[2] mt-6 flex items-center gap-4"
      >
        <button
          :style="{
            backgroundColor: attending.isAttending(item.id!)
              ? 'transparent'
              : palette?.secondary
                ? palette.secondary
                : '#797FFF',
            borderColor: palette?.secondary ? palette.secondary : '#797FFF',
          }"
          class="flex select-none items-center justify-center rounded-lg border px-12 py-3 font-sourcePro text-sm font-bold text-white hover:opacity-90"
          @click="attending.toggleAttending(item)"
        >
          Attend
        </button>
        <button
          :style="{
            color: palette?.secondary ? palette.secondary : '#797FFF',
          }"
          class="flex select-none items-center justify-center rounded-lg py-4 font-sourcePro text-sm font-bold hover:opacity-90"
          @click="attending.openModal(item)"
        >
          Learn more
        </button>
      </div>
    </template>
  </hover-card>
</template>

<script lang="ts" setup>
import PinIcon from '@/icons/PinIconFill.vue'
import ClockIcon from '@/icons/ClockIcon.vue'
import CalendarIconV2 from '@/icons/CalendarIconV2.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import HoverCard from '@/components/ui/HoverCard.vue'
import { PropType } from 'vue'
import { EventActivity } from '@/models/EventActivity'
import { ConventionPalette } from '@/models/ConventionEvent'
import { DateTime } from 'luxon'
import { useAttending } from '@/utils/attending'

const props = defineProps({
  item: {
    type: Object as PropType<EventActivity>,
    required: true,
  },
  palette: {
    type: Object as PropType<ConventionPalette>,
    default: () => {},
  },
})

const attending = useAttending()
</script>
