<template>
  <abstract-card-with-scroll-items
    :banner="restaurant.banner?.path"
    :description="restaurant.description"
    :items="restaurant.products"
    :items-count="restaurant.products_count"
    :link="`/events/${eventSlug}/restaurants/${restaurant.slug}`"
    :name="restaurant.name"
    :tags="restaurant.menu_item_tags?.map((t) => t.name)"
  >
    <template #overlay="{ opacity }">
      <div
        :style="{ opacity: opacity }"
        class="p-4 text-2xl font-medium text-white"
      >
        {{ restaurant.name }}
      </div>
      <div
        v-if="restaurant.percentile > 0"
        class="flex h-full w-full items-end rounded-md pt-6 text-white"
      >
        <div
          class="flex flex-row items-center gap-1 bg-slate-900 px-2 py-1 text-xs"
        >
          Top {{ restaurant.percentile }}%
        </div>
        <div
          v-if="restaurant.overall_rating"
          class="flex flex-row items-center gap-1 rounded-tr-lg px-2 py-1"
          style="background-color: #ff390e"
        >
          <div class="text-xs">
            {{ restaurant.overall_rating }}
          </div>
          <font-awesome-icon
            class="text-xs"
            icon="star"
          />
        </div>
      </div>
    </template>
    <template #info-top>
      <div>
        <span
          class="text-sm"
          style="color: #bfc6cd"
        >
          {{ openingText }}
        </span>
      </div>
    </template>
    <template #info-additions>
      <div class="font-heading text-sm text-slate-400">
        <span v-if="location.loaded">
          {{ getDistance(restaurant.latitude, restaurant.longitude) }}
        </span>
      </div>
    </template>
    <template #other-info>
      <div class="hidden flex-row gap-4 md:flex">
        <review-presenter
          v-if="restaurant.links?.find((t) => t.type === 'yelp')"
          :link="restaurant.links?.find((t) => t.type === 'yelp')?.url"
          :rating="yelpRatings.reviewStars"
          :review-count="yelpRatings.reviewCount"
          name="Yelp"
        />
        <review-presenter
          v-if="restaurant.links?.find((t) => t.type === 'open-table')"
          :link="restaurant.links?.find((t) => t.type === 'open-table')?.url"
          :rating="otRatings.reviewStars"
          :review-count="otRatings.reviewCount"
          name="Open Table"
        />
      </div>
    </template>
    <template #item="{ item }">
      <item-card
        :key="item.id"
        :event-slug="eventSlug"
        :item="item"
        class="mx-auto"
        image-class="md:aspect-square aspect-video"
      />
    </template>
  </abstract-card-with-scroll-items>
</template>

<script lang="ts" setup>
import AbstractCardWithScrollItems from '@/components/ui/AbstractCardWithScrollItems.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'
import { calculateDistance } from '@/plugins/CalculateDistance'
import ReviewPresenter from '@/components/ui/ReviewPresenter.vue'
import { computed, PropType } from 'vue'
import { DateTime } from 'luxon'
import { useUserLocation } from '@/utils/composables/location'
import Vendor from '@/models/Vendor'

const props = defineProps({
  restaurant: {
    type: Object as PropType<Vendor>,
    required: true,
  },
  eventSlug: {
    type: String,
    default: null,
  },
})

const location = useUserLocation()

const getDistance = (vendorLatitude, vendorLongitude) => {
  return calculateDistance(
    location.value.latitude,
    location.value.longitude,
    vendorLatitude,
    vendorLongitude
  )
}

const yelpRatings = computed(() => {
  if (!props.restaurant.ratings) {
    return {
      reviewStars: 0,
      reviewCount: 0,
    }
  }
  const [yelp_review_stars, yelp_review_count] =
    props.restaurant.ratings.filter((item) =>
      ['yelp_review_count', 'yelp_review_stars'].includes(item.name)
    )

  return {
    reviewStars: yelp_review_stars?.value ?? 0,
    reviewCount: yelp_review_count?.value ?? 0,
  }
})

const otRatings = computed(() => {
  if (!props.restaurant.ratings) {
    return {
      reviewStars: 0,
      reviewCount: 0,
    }
  }
  const [ot_review_stat, ot_review_count] = props.restaurant.ratings.filter(
    (item) => ['ot_review_stat', 'ot_review_count'].includes(item.name)
  )
  return {
    reviewStars: ot_review_stat?.value ?? 0,
    reviewCount: ot_review_count?.value ?? 0,
  }
})

const openingText = computed(() => {
  if (props.restaurant.opening_schedule) {
    // TODO this dates are coming as LA time, but they need to be in UTC
    // FIXME this is a hack for now but will not work in the future
    const starts = DateTime.fromISO(
      props.restaurant.opening_schedule.opens_at,
      {
        zone: 'America/Los_Angeles',
      }
    ).setZone('UTC')
    const ends = DateTime.fromISO(props.restaurant.opening_schedule.closes_at, {
      zone: 'America/Los_Angeles',
    }).setZone('UTC')
    const now = DateTime.now().setZone('America/Los_Angeles')

    if (starts <= now && ends >= now) {
      if (now.diff(ends, 'minutes').minutes < 60) {
        return 'Closing Soon'
      }
      return 'Open Until ' + ends.toFormat('h:mm a')
    } else if (starts > now) {
      if (starts.diff(now, 'hours').hours < 24) {
        if (starts.diff(now, 'minutes').minutes < 60) {
          return 'Opens ' + starts.toRelative()
        }
        return 'Opens at ' + starts.toFormat('h:mm a')
      } else {
        return 'Opens at ' + starts.toFormat('cccc')
      }
    } else if (ends < now) {
      return 'Closed'
    }
  }
  return ''
})
</script>

<style scoped></style>
