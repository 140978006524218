<template>
  <listbox
    v-model="selected"
    as="div"
  >
    <listbox-label
      v-if="labelName"
      class="block text-sm font-medium leading-6 text-gray-900 mb-2"
    >
      {{ labelName }}
    </listbox-label>
    <div class="relative">
      <listbox-button class="relative w-full rounded-md py-2.5 pl-3 pr-10 text-left text-gray-900 bg-slate-100 shadow-sm active:bg-slate-200 outline-none sm:text-sm sm:leading-6 cursor-pointer select-none">
        <span class="block truncate text-lg font-semibold">{{ selected }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <font-awesome-icon
            icon="caret-down"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </listbox-button>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <listbox-options class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base md:text-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <listbox-option
            v-for="(option, optionIndex) in options"
            :key="optionIndex"
            v-slot="{ active, selected: optionSelected }"
            as="template"
            :value="option"
            @click="emits('selected', optionIndex)"
          >
            <li
              class="relative flex flex-row flex-nowrap justify-start items-center w-50 cursor-pointer select-none py-2 px-2"
              :class="[active ? 'bg-blue-primary text-white' : 'text-gray-900']"
            >
              <font-awesome-icon
                icon="check"
                class="flex items-center text-sm md:text-base mr-2"
                :class="[active ? 'text-white' : 'text-blue-primary', optionSelected ? 'text-blue-primary' : 'text-white']"
                aria-hidden="true"
              />

              <div
                class="block truncate w-full"
                :class="[optionSelected ? 'font-semibold' : 'font-normal']"
              >
                {{ option }}
              </div>
            </li>
          </listbox-option>
        </listbox-options>
      </transition>
    </div>
  </listbox>
</template>

<script setup>
import { computed, ref } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const emits = defineEmits(['selected']);




















































defineOptions({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dropdown'
})




















































const props = defineProps({
    labelName: {
        type: String,
        default: null
    },
    options: {
        type: Array,
        default: null
    },
    defaultSelectionIndex: {
        type: Number,
        default: 0
    }
});

const selected = ref(props.options[props.defaultSelectionIndex]);
</script>

<style scoped>

</style>
